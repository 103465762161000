import React, { Component } from 'react';
import DatesPicker from '../../../helpers/DatesPicker';

import M from 'materialize-css';
import '../../../css/DateFormReport.css';

import { connect } from 'react-redux';
import { initCoursesAndLecturers, getLecturersByCourse, getCoursesByDatesAndLecturer, getAllCoursesByDates, getAllLecturersByDates, getAllOpinions } from '../../../actions/evalReportAction';

import { REPORT_DATES, CHOSEN_COURSE_ID, CHOSEN_LECTURER_ID } from '../../../helpers/sessionKeys';

class DateForm extends Component {

    state = {
        datesNotValid: false,
        chosenCourse: "-1",
        chosenLecturer: "-1"
    }

    componentWillMount() {
        let courseID = sessionStorage.getItem(CHOSEN_COURSE_ID);
        let lecturerID = sessionStorage.getItem(CHOSEN_LECTURER_ID);
        if (courseID)
            this.setState({ chosenCourse: courseID })
        if (lecturerID)
            this.setState({ chosenLecturer: lecturerID })
    }

    initializeMaterializeSelect = () => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }

    componentDidMount() {
        this.initializeMaterializeSelect();
        this.props.initCoursesAndLecturers(this.dates.dateFrom, this.dates.dateTo, this.state.chosenCourse === "-1" ? '' : this.state.chosenCourse, this.state.chosenLecturer === "-1" ? '' : this.state.chosenLecturer);
    }

    componentDidUpdate() {
        this.initializeMaterializeSelect();
    }

    onDatesChange = (valid) => {
        valid ? (() => {
            this.setState({ datesNotValid: false })
            this.setState({
                chosenCourse: "-1",
                chosenLecturer: "-1"
            })
            this.props.initCoursesAndLecturers(this.dates.dateFrom, this.dates.dateTo, '', '');
        })() :

            this.setState({
                datesNotValid: true
            })
    }


    onSubmit = (e) => {
        e.preventDefault();
        let courseID = '', lecturerID = '';
        // let dates = { dateFrom: this.dates.dateFrom, dateTo: this.dates.dateTo };

        if (this.state.chosenCourse !== "-1")
            courseID = this.state.chosenCourse;
        if (this.state.chosenLecturer !== "-1")
            lecturerID = this.state.chosenLecturer;

        sessionStorage.setItem(REPORT_DATES, JSON.stringify(this.dates));
        sessionStorage.setItem(CHOSEN_LECTURER_ID, lecturerID);
        sessionStorage.setItem(CHOSEN_COURSE_ID, courseID);

        this.props.getAllOpinions(this.dates.dateFrom, this.dates.dateTo, courseID, lecturerID);
    }

    onCourseChange = (e) => {
        this.setState({
            chosenCourse: e.target.value
        })
        if (e.target.value !== "-1") {
            this.props.getLecturersByCourse(this.dates.dateFrom, this.dates.dateTo, e.target.value);
            document.querySelector(".report .coursesSelect select").style.textIndent = "unset";
        }
        else {
            document.querySelector(".report .coursesSelect select").style.textIndent = "30px";
            if (this.state.chosenLecturer !== "-1") this.setState({ chosenLecturer: "-1" });
            this.props.initCoursesAndLecturers(this.dates.dateFrom, this.dates.dateTo, '', '');
        }
    }

    onLecturerChange = (e) => {
        this.setState({
            chosenLecturer: e.target.value
        })
        if (e.target.value !== "-1") {
            this.props.getCoursesByDatesAndLecturer(this.dates.dateFrom, this.dates.dateTo, e.target.value);
        }
        else {
            if (this.state.chosenCourse !== "-1") this.setState({ chosenCourse: "-1" });
            this.props.initCoursesAndLecturers(this.dates.dateFrom, this.dates.dateTo, '', '');
        }
    }

    render() {
        return (
            <div className="date-form report">
                <form onSubmit={this.onSubmit} className="card reportForm">
                    <div className="content" style={{ direction: 'rtl' }}>
                        <h5 style={{ textAlign: 'center' }}>דוחות הערכה למרצים</h5><br></br>
                        <br></br>
                        <DatesPicker onDatesChange={this.onDatesChange} onInit={(dates) => this.dates = dates} sessionKey={REPORT_DATES} />
                        <div className="selectsWrapper">
                            {this.generateSelect("בחר קורס (אופציונלי):", "coursesSelect", this.showCoursesOptions, this.onCourseChange, this.state.chosenCourse)}
                            {this.generateSelect("בחר מרצה (אופציונלי):", "lecturerSelect", this.showLecturersOptions, this.onLecturerChange, this.state.chosenLecturer)}
                        </div>
                        <br></br>
                        <div className="center">
                            <button disabled={this.state.datesNotValid} className="btn #f57f17 yellow darken-4" type="submit">הצג דוח הערכה</button>
                        </div><br></br>
                        <div className="center" style={{ fontSize: '11px' }}>
                            <div>רשימת הקורסים ורשימת המרצים מכילות נתונים רק לגבי קורסים/מרצים שקיבלו דירוג 
                            </div>
                        </div>
                    </div>
                </form>
            </div>

        )
    }

    generateSelect = (label, selectClass, showOptions, onValueChanged, state) => {
        return <div className="center">
            <label>{label}</label>
            <div onChange={onValueChanged} className={selectClass}>
                <select className="browser-default" value={state} readOnly>
                    {showOptions()}
                </select>
            </div>
        </div>
    }

    showCoursesOptions = () => {
        const { courses } = this.props;
        return <>
            <option className="defaultChoice" value="-1">כל הקורסים</option>
            {courses && courses.map((option, index) => <option value={option.id} key={index}>{option.id} - {option.name}</option>)}
        </>
    }

    showLecturersOptions = () => {
        const { lecturers } = this.props;
        return <>
            <option className="defaultChoice" value="-1">כל המרצים</option>
            {lecturers && lecturers.map((option, index) => <option value={option.id} key={index}>{option.name}</option>)}
        </>
    }

}

const mapStateToProps = ({ reportForm }) => {
    return {
        courses: reportForm.courses,
        lecturers: reportForm.lecturers
    }
}

export default connect(mapStateToProps, { initCoursesAndLecturers, getLecturersByCourse, getCoursesByDatesAndLecturer, getAllCoursesByDates, getAllLecturersByDates, getAllOpinions })(DateForm);