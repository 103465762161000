import React, { Component } from 'react';
import '../../../css/DateForm.css';

import { connect } from 'react-redux';
import { uploadExcelFile } from '../../../actions/excelAction';
import { DATES } from '../../../helpers/sessionKeys';
import DatesPicker from '../../../helpers/DatesPicker';
import { isValid } from '../../../helpers/dateFormats';

class DateForm extends Component {

    fileInput = React.createRef();
    datePickerChild = React.createRef();
    dates = [];

    state = {
        fileData: null,
        fileNotValidError: null,
        datesNotValid: false,
        isInstitute: false
    }

    toggleInstituteCheckbox = () => {
        let isInstitute = this.state.isInstitute;
        this.setState({ isInstitute: !isInstitute });
    }

    onFileChange = (e) => {
        if (!e.target.files[0]) this.setState({ fileData: null, fileNotValidError: null });
        else {
            let validationResult = this.validateFile(e.target.files[0]);

            if (validationResult !== 'ok') { //file not valid
                this.fileInput.current.value = "";
                this.setState({
                    fileNotValidError: validationResult,
                    fileData: null
                })
            }
            else {
                let fileData = {
                    key: e.target.name,
                    data: e.target.files[0]
                }
                this.setState({ fileData, fileNotValidError: null })

                let date = this.extractFileNameDate(e.target.files[0].name);
                if (date) {
                    this.datePickerChild.current.setDatesAfterChangeFile(date); //call method setDates of datepicker childelement.
                }
            }
        }
    }

    extractFileNameDate = (fileName) => {  //only for opening file - all files ends with the course start date
        let startIndexOfDate = (fileName.indexOf('.') - 2);
        let lastIndexOfDate = fileName.lastIndexOf('.');
        if (startIndexOfDate < 0 || lastIndexOfDate < 0 || lastIndexOfDate === startIndexOfDate) return null;
        let date = fileName.slice(startIndexOfDate, lastIndexOfDate).trim();
        if (isNaN(parseInt(date.charAt(0)))) {
            date = date.slice(1);
        }
        return isValid(date, "DD.MM.YYYY"); //if valid return it as Date, else return false.
    }

    //check file validity: 1. match its type to one of the allowed types. 2.check if its mime type is correct. 3. check its size is under the allowed limit.
    validateFile(file) {
        let allowedFileTypes = /xlsx|xls/;
        const extName = allowedFileTypes.test(file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase());
        // const mimeType = allowedFileTypes.test(file.type);
        const fileSize = file.size <= 1000000;

        if (!extName) {
            return 'סוג הקובץ שנבחר אינו נתמך. יש לבחור רק קבצים מסוג xls/xlsx.';
        }
        else if (!fileSize) {
            return 'גודל הקובץ עובר את הגבול המותר.';
        }
        else {
            return 'ok';
        }

    }

    onDatesChange = (valid) => {
        valid ?
            this.setState({
                datesNotValid: false
            }) :
            this.setState({
                datesNotValid: true
            })
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.uploadExcelFile(this.state.fileData, this.dates, this.formType, this.state.isInstitute); //call to excel action
    }

    renderIsInstituteCheckbox = () => {  //will be render only in evaluation formType
        return (
            <div className="instCheckboxWrapper">
                <label>
                    <input type="checkbox" checked={this.state.isInstitute} onChange={this.toggleInstituteCheckbox} />
                    <span>קורס מכון</span>
                </label>
            </div>
        )
    }

    render() {
        this.formType = this.props.location.pathname.split('/')[3];
        const title = this.formType === "opening" ? "טפסי פתיחה והכרות - עדכון נתונים" : "טפסי חוות דעת - עדכון נתונים"
        return (
            <div className="date-form row">
                <form onSubmit={this.onSubmit} className="col s6 offset-s3 card">
                    <div className="row" style={{ direction: 'rtl' }}>
                        <h5 style={{ textAlign: 'center' }}>{title}</h5><br></br>
                        <div action="#">
                            <div className="baseWrapper">
                                <div className="file-field input-field">
                                    <div className="btn">
                                        <span>File</span>
                                        <input type="file" name="selectedFile" accept=".xls,.xlsx" onChange={this.onFileChange} />
                                    </div>
                                    <div className="file-path-wrapper">
                                        <input ref={this.fileInput} className="file-path validate" type="text" placeholder="בחר קובץ אקסל" />
                                    </div>
                                </div>
                                {this.formType === "evaluation" ? this.renderIsInstituteCheckbox() : <></>}
                            </div>
                            {this.state.fileNotValidError ? <div className="errorMsg center">{this.state.fileNotValidError}</div> : ""}
                        </div>
                        <br></br>
                        <DatesPicker ref={this.datePickerChild} onDatesChange={this.onDatesChange} onInit={(dates) => this.dates = dates} sessionKey={DATES} validRange='7' />
                        {this.formType === "evaluation" && this.state.fileData && !this.state.fileNotValidError ?
                            <div className="center"><label className="label">טווח התאריכים מחושב על פי תאריך תחילת הקורס המופיע בשם הקובץ הנבחר</label></div>
                            :
                            <></>
                        }
                        <br></br>
                        <div className="center">
                            <button disabled={!this.state.fileData || this.state.datesNotValid} className="btn #f57f17 yellow darken-4" type="submit">התחל ייבוא נתונים</button>
                        </div>
                    </div>
                </form>
            </div>

        )
    }
}


export default connect(null, { uploadExcelFile })(DateForm)
