import http from '../api/server';
import { SIGN_OUT } from './types';
import { EXCEL_PERSONS, DATES, FORM_TYPE } from '../helpers/sessionKeys';
import history from '../helpers/history';
import { reportError } from './errorActions';

export const uploadExcelFile = (fileData, dates, formType, isInstitute) => {
    return (dispatch) => {
        let formData = new FormData();
        formData.append(fileData.key, fileData.data, fileData.data.name);
        http.post("/uploadFile/" + formType + "/" + isInstitute, formData).then(res => {
            sessionStorage.setItem(EXCEL_PERSONS, JSON.stringify(res.data));
            sessionStorage.setItem(DATES, JSON.stringify(dates));
            sessionStorage.setItem(FORM_TYPE, formType);
            history.push("/admin/form");
        }).catch(err => {
            if (err.response) {
                if (err.response.status === 401)
                    dispatch({ type: SIGN_OUT });
                else if (err.response.status === 404) {
                    err.message = "FILE_UPLOAD_ERROR"
                    reportError(err, dispatch);
                }
            }
            else
                reportError(err, dispatch);
        })
    }
}