export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const USER_NOT_FOUND_ERROR = "USER_NOT_FOUND";
export const INIT_DATA = "INIT_DATA";
export const UPDATE_CURRENT_DB_FORM = "UPDATE_CURRENT_DB_FORM";
export const SUBMIT_CURRENT_DB_FORM = "SUBMIT_CURRENT_DB_FORM";
export const SUBMIT_CURRENT_DB_FORM_ERROR = "SUBMIT_CURRENT_DB_FORM";
export const UPDATE_CURRENT_EXCEL_FORM = "UPDATE_CURRENT_EXCEL_FORM";
export const EMPTY_FORM = "EMPTY_FORM";
export const UPDATE_POPUP_CONFIG = "UPDATE_POPUP_CONFIG";
export const REPORT_ERROR = "REPORT_ERROR";
export const CLEAN_ERRORS = "CLEAN_ERRORS";
export const UPDATE_LOADING = "UPDATE_LOADING";
export const INIT_COURSES_AND_LECTURERES = "INIT_COURSES_AND_LECTURERES";
export const UPDATE_COURSES = "UPDATE_COURSES";
export const INIT_REPORT = "INIT_REPORT";
export const UPDATE_LECTURERS = "UPDATE_LECTURERS";
export const UPDATE_CURRENT_COURSE = "UPDATE_CURRENT_COURSE";
export const GET_DB_NAME = "GET_DB_NAME";
