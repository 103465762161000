import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import M from 'materialize-css';
import '../css/PopUp.css';
import { connect } from 'react-redux';
import { cleanErrors } from '../actions/errorActions';

class PopUp extends Component {
    //mandatory props- 
    //title, messages <popUp title="some title" messages="some messages"/>

    //possible props-
    //1. if we want to change from alert popup to confirm popup we need to pass 'confirm' prop where popup tag invoked:<popUp confirm={true} />
    //2. when we pass confirm prop, we must pass function prop, called 'onConfirm' : <popUp confirm={true} onConfirm={this.componentFunc}/>
    //3. we can also pass 'onCancel' prop for event called after aborted :  <popUp confirm={true} onCancel={this.componentFunc}/>
    //4. if we want to change default btns we need to pass 'btn' json prop(eith close and confirm inner property) where popup tag invoked:<popUp btns={{unConfirm:"No",confirm:"Yes"}} />

    defaultBtnContent = {
        close: "סגור",
        confirm: "כן",
        unConfirm: "לא"
    }

    componentDidMount() {
        var elem = document.getElementById('modal1');
        M.Modal.init(elem, {
            onCloseEnd: () => {
                if (this.props.open)
                    this.props.cleanErrors();
            }
        });

        if (this.props.open)
            M.Modal.getInstance(elem).open();
    }

    componentDidUpdate() {
        var elem = document.getElementById('modal1');
        if (this.props.open)
            M.Modal.getInstance(elem).open();
    }

    renderBtns = () => {
        if (!this.props.confirm) {
            if (!this.props.btns)
                return <button className="modal-close btn-flat">{this.defaultBtnContent.close}</button>
            else
                return <button className="modal-close btn-flat">{this.props.btns.close}</button>
        }
        else {
            if (!this.props.btns) {
                return (
                    <>
                        <button className="modal-close btn-flat" onClick={() => this.props.onCancel()}>{this.defaultBtnContent.unConfirm}</button>
                        <button className="modal-close btn-flat" onClick={() => this.props.onConfirm()}>{this.defaultBtnContent.confirm}</button>
                    </>
                )
            }
            else {
                return (
                    <>
                        <button className="modal-close btn-flat" onClick={() => this.props.onCancel()}>{this.props.btns.unConfirm}</button>
                        <button className="modal-close btn-flat" onClick={() => this.props.onConfirm()}>{this.props.btns.confirm}</button>
                    </>
                )
            }
        }
    }

    render() {
        return (
            ReactDOM.createPortal(
                <div id="modal1" className="modal">
                    <div className="modal-content">
                        <h4>{this.props.title}</h4>
                        {
                            this.props.messages && this.props.messages.map((message, index) => {
                                return <div key={index}><h6 dangerouslySetInnerHTML={{ __html: message }}></h6></div>
                            })
                        }
                    </div>
                    <div className="modal-footer">
                        {this.renderBtns()}
                    </div>
                </div>,
                document.getElementById('root')
            )
        )
    }
}

export default connect(null, { cleanErrors })(PopUp);
