import React, { Component } from 'react';
import '../../../../../css/ExcelForm.css';
import { connect } from 'react-redux';
import { getKeyForPerson } from '../FormLayout';
import { updateCurrentDBPerson } from '../../../../../actions/formsActions';
import { FORM_TYPE } from '../../../../../helpers/sessionKeys';
import $ from 'jquery';

class ExcelForm extends Component {

    formType = sessionStorage.getItem(FORM_TYPE);
    formFields = {
        opening: [
            { /*empty field row*/ },
            { label: "", onlyNewPerson: true },
            { label: "שם פרטי", existInDb: false, hasCopy: true },
            { label: "שם משפחה", existInDb: false, hasCopy: true },
            { label: "פרטי באנגלית", existInDb: false, hasCopy: true },
            { label: "משפחה באנגלית", existInDb: false, hasCopy: true },
            { label: "טלפון סלולרי", existInDb: false, hasCopy: true, isObject: true },
            { label: "טלפון עבודה", existInDb: false, hasCopy: true, isObject: true },
            { /*empty field row*/ },
            { /*empty field row*/ },
            { label: "אימייל", existInDb: false, hasCopy: true },
            { label: "כתובת למשלוח", existInDb: false, hasCopy: false },
            { /*empty field row*/ },
            { label: "חברה", existInDb: false, hasCopy: true },
            { label: "מאשר קבלת דואר", existInDb: false, hasCopy: false }
        ]
        ,
        evaluation: [
            { /*empty field row*/ },
            { label: "", onlyNewPerson: true },
            { label: "שם פרטי", existInDb: false, hasCopy: true },
            { label: "שם משפחה", existInDb: false, hasCopy: true },
            { label: "טלפון סלולרי", existInDb: false, hasCopy: true, isObject: true },
            { label: "טלפון עבודה", existInDb: false, hasCopy: true, isObject: true },
            { /*empty field row*/ },
            { /*empty field row*/ },
            { label: "אימייל", existInDb: false, hasCopy: true },
            { label: "חברה", existInDb: false, hasCopy: true },
            { label: "מאשר קבלת דואר", existInDb: false, hasCopy: false }
        ]
    }


    handleOnClick = (e, label, fieldIsObject) => {
        e.preventDefault();
        let currentDbPerson = this.props.formData.currentDBPerson;
        let currentExcelPerson = this.props.formData.currentExcelPerson.node;
        let person = {
            ...currentDbPerson,
            changed: true,
            node: {
                ...currentDbPerson.node,
                [getKeyForPerson(label)]: fieldIsObject ? // if field of person is object (phone/workPhone), then we want to take excel person field value and replace node field.value (in order to keep node field as obj and keep its id field)
                    {
                        id: currentDbPerson.node[getKeyForPerson(label)].id,
                        value: currentExcelPerson[getKeyForPerson(label)]
                    }
                    : currentExcelPerson[getKeyForPerson(label)]  //other way, the field is string, so we replace it as is.
            }
        }
        this.props.updateCurrentDBPerson(person)
    }

    checkAllExistInDb = (fields) => {
        //loop over the fields to mark existInDb prop
        for (let index = 0; index < fields.length; index++) {
            const field = fields[index];
            this.checkExistInDb(field);
        }
    }

    checkExistInDb = (field) => {
        const { currentExcelPerson, currentDBPerson, companies } = this.props.formData
        const excelPerson = currentExcelPerson.node;
        const dbPerson = currentDBPerson.node;
        const propKey = getKeyForPerson(field.label);
        let excelProp = excelPerson[propKey]; //prop from excel person
        let dbProp = dbPerson[propKey]; //prop form db person   


        excelProp = excelProp ? excelProp : "_empty_string_";
        //phones in dbPerson are objects not a value
        if (propKey === "phoneNumber" || propKey === "workPhoneNumber" || propKey === "extraPhone1" || propKey === "extraPhone2")
            dbProp = dbProp.value ? dbProp.value : "_empty_string_";
        else
            dbProp = dbProp ? dbProp : "_empty_string_";

        const checkPhone = () => {
            //check the value of the propKey if he is included in one of the 4 numbers 
            field.existInDb = dbPerson["phoneNumber"].value === excelProp;
            if (field.existInDb) return;

            field.existInDb = dbPerson["workPhoneNumber"].value === excelProp;
            if (field.existInDb) return;

            field.existInDb = dbPerson["extraPhone1"].value === excelProp;
            if (field.existInDb) return;

            field.existInDb = dbPerson["extraPhone2"].value === excelProp;
        }
        switch (propKey) {
            case "phoneNumber":
                checkPhone();
                break;
            case "workPhoneNumber":
                checkPhone();
                break;
            case "company":
                field.hasCopy = companies.some(company => company.name === excelProp);
                field.existInDb = dbProp.toLowerCase() === excelProp.toLowerCase();
                break;
            case "mailApproval":
                if (this.formType === "opening") {
                    const convertedValue = excelProp === "מאשר" ? "YES" : "NO"
                    field.existInDb = dbProp === convertedValue
                } //if its not opening we cant know the value cuz evaluation form has open text field on google forms, so defult will be false
                else field.existInDb = false;
                break;
            default:
                field.existInDb = dbProp.toLowerCase() === excelProp.toLowerCase();
                break;
        }
    }

    componentDidMount() {
        //prevent key enter to copy or submit - prevent invoke default button
        $(document).ready(function () {
            $(window).keydown(function (event) {
                if (event.keyCode === 13) {
                    event.preventDefault();
                    return false;
                }
            });
        });
    }


    render() {
        const currentExcelPerson = this.props.formData.currentExcelPerson.node;
        const fields = this.formFields[this.formType];
        const { firstName, lastName } = currentExcelPerson;
        let name = "לא צויין";
        // eslint-disable-next-line
        if (firstName || lastName) name = (firstName ? firstName : "") + " " + (lastName ? lastName : "");
        this.checkAllExistInDb(fields);
        return (
            <div className="card">
                <div className="title">
                    <div className="student-title">פרטי התלמיד כפי שהגיעו מהטופס</div>
                    <div className="sub-student-title"> תלמיד #{Number.parseInt(this.props.formData.currentExcelPerson.index) + 1} - {name.substring(0, 25)}</div>
                </div>
                {fields && fields.map((field, index) => {
                    if (!this.props.formData.currentDBPerson.newPerson && field.onlyNewPerson) return null
                    return (
                        <div className="form-field" key={index}>
                            <div className="field-content main">
                                <span className={field.label ? "field-content-label" : "field-content-label no-lable"}>{field.label ? (field.label + ':') : "no label"}</span>
                                <span className={field.existInDb ? 'field-content-value green-text' : 'field-content-value red-text'}><b>{field.label ? currentExcelPerson[getKeyForPerson(field.label)] : null}</b></span>
                            </div>

                            <div className="field-content sub">
                                {(currentExcelPerson[getKeyForPerson(field.label)] && !field.existInDb && field.hasCopy) ?
                                    <button onClick={(e) => this.handleOnClick(e, field.label, field.isObject)} className="btn">
                                        {">>"}
                                    </button>
                                    : ''
                                }
                            </div>


                        </div>
                    )
                })}
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        formData: state.DB
    }
}

export default connect(mapStateToProps, { updateCurrentDBPerson })(ExcelForm)