import { INIT_DATA, UPDATE_CURRENT_DB_FORM, SUBMIT_CURRENT_DB_FORM, UPDATE_CURRENT_EXCEL_FORM, EMPTY_FORM, UPDATE_CURRENT_COURSE } from '../actions/types';

// const initData = {
//     companies: '',
//     cities: '',
//     personsFromExcel: '',
//     courses: '',
//     currentExcelPerson: {node:'', index:''},
//     currentDBPerson: {node:'', index:'' ,newPerson: false},
// }

export default (data = null, action) => {
    switch (action.type) {
        case INIT_DATA:
            return action.payload;
        case UPDATE_CURRENT_DB_FORM:
            return {
                ...data,
                currentDBPerson: action.payload
            }
        case SUBMIT_CURRENT_DB_FORM:
            return {
                ...data,
                ...action.payload
            }
        case UPDATE_CURRENT_EXCEL_FORM:
            return {
                ...data,
                ...action.payload
            }
            case UPDATE_CURRENT_COURSE:
            return{
                ...data,
                ...action.payload
            }
        case EMPTY_FORM:
            return null;
        
        default:
            return data;
    }

}