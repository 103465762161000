import React, { Component } from 'react';
import '../../../../../css/DbForm.css';
import { connect } from 'react-redux';
import { updateCurrentDBPerson, emptyPersonJsonFormats, getRegisteredDetails } from '../../../../../actions/formsActions';
import { FORM_TYPE } from '../../../../../helpers/sessionKeys';
import { getKeyForPerson } from '../FormLayout';
import { showPopUp, updatePopUpConfig } from '../../../../../actions/popupActions';
import $ from 'jquery';
import M from 'materialize-css';

import 'react-select/dist/react-select.css'
import 'react-virtualized/styles.css'
import 'react-virtualized-select/styles.css'

// Then import the virtualized Select HOC
import VirtualizedSelect from 'react-virtualized-select'

class DbForm extends Component {
    cityOptions = [];
    companyOptions = [];
    state = {
        chosenRegisteredIndex: "-1" //for the course registers select list in the bottom 
    }

    formType = sessionStorage.getItem(FORM_TYPE);
    formFields = {
        opening: [
            { label: "מין", type: 'radio', element: "input", buttonsName: ["זכר", "נקבה"], newPerson: true },
            { label: "שם פרטי", type: 'text', element: "input" },
            { label: "שם משפחה", type: 'text', element: "input" },
            { label: "פרטי באנגלית", type: 'text', element: "input", },
            { label: "משפחה באנגלית", type: 'text', element: "input", },
            { label: "טלפון סלולרי", type: 'text', element: "input", },
            { label: "טלפון עבודה", type: 'text', element: "input", },
            { label: "טלפון נוסף 1", type: 'text', element: "input", },
            { label: "טלפון נוסף 2", type: 'text', element: "input", },
            { label: "אימייל", type: 'text', element: "input", },
            { label: "רחוב", type: 'text', element: "input", },
            { label: "עיר", type: 'text', element: "select", },
            { label: "חברה", type: 'text', element: "select", },
            { label: "מאשר קבלת דואר", type: 'text', element: "checkbox", toggleName: { trueName: "מאשר", falseName: "לא מאשר" }, }
        ],
        evaluation: [
            { label: "מין", type: 'radio', element: "input", buttonsName: ["זכר", "נקבה"], newPerson: true },
            { label: "שם פרטי", type: 'text', element: "input" },
            { label: "שם משפחה", type: 'text', element: "input" },
            { label: "טלפון סלולרי", type: 'text', element: "input", },
            { label: "טלפון עבודה", type: 'text', element: "input", },
            { label: "טלפון נוסף 1", type: 'text', element: "input", },
            { label: "טלפון נוסף 2", type: 'text', element: "input", },
            { label: "אימייל", type: 'text', element: "input", },
            { label: "חברה", type: 'text', element: "select", },
            { label: "מאשר קבלת דואר", type: 'text', element: "checkbox", toggleName: { trueName: "מאשר", falseName: "לא מאשר" } }
        ]
    }

    ///////generates the fields and functionality
    generateAutoCompleteElements = ({ label }, index) => {
        const currentPerson = this.props.formData.currentDBPerson.node;
        const propKey = getKeyForPerson(label);
        const value = currentPerson[propKey];
        const options = propKey === 'city' ? this.cityOptions : this.companyOptions;

        const isHebrew = (str) => {
            var res = str.search(/[a-zA-Z]/) === -1;
            return res;
        }

        return (
            <div className="input-field form-field dbForm" key={index}>
                <div className="field-content"> {label + ':'}</div>
                <VirtualizedSelect
                    options={options}
                    onChange={(selected) => this.handleFieldsOnChange(selected, label)}
                    value={value} placeholder="בחר מהרשימה" noResultsText="לא נמצאו תוצאות"
                    optionRenderer={({ optionIndex, style, option, selectValue }) => {
                        let costumStyle = { ...style, color: "black", textAlign: "left" };
                        if (isHebrew(option.value))
                            costumStyle["textAlign"] = "right";
                        return <a key={optionIndex} style={costumStyle} onClick={() => selectValue(option)}>{option.value}</a>
                    }}
                />
            </div>
        )
    }

    generateInputsElements = ({ label, type, buttonsName }, index) => {
        const { node, newPerson } = this.props.formData.currentDBPerson;
        const currentPerson = node;
        const prop = currentPerson[getKeyForPerson(label)];
        if (type === 'text')
            return (
                <div className="form-field dbForm" key={index}>
                    <div className="field-content"> {label + ':'}</div>
                    <div className="field-content field-content-value">
                        <input className="text-right"
                            type={type}
                            //in phone we have an object with id and value
                            value={
                                //if we got a value prop?
                                prop.value ?
                                    //if yes show value
                                    prop.value :
                                    //if we have value but its empty string
                                    prop.value === "" ?
                                        //if yes show value
                                        prop.value :
                                        //if not its not an object with a value prop
                                        prop
                            }
                            onChange={(e) => this.handleFieldsOnChange(e, label)}
                        />
                    </div>

                </div>

            )
        if (type === 'radio' && newPerson)
            return (
                <div className="form-field dbForm" key={index}>
                    <div className="field-content"> {label + ':'}</div>
                    <div className="field-content field-content-value flex-radio">
                        {buttonsName && buttonsName.map((name, index) => {
                            return (
                                <label key={index}>
                                    <input name="group1"
                                        type={type}
                                        defaultValue={name}
                                        onChange={(e) => this.handleFieldsOnChange(e, label)}
                                    />
                                    <span>{name}</span>
                                </label>
                            )
                        })}
                    </div>
                </div>
            )
        return null;
    }

    generateToggleElements = ({ label, toggleName }, index) => {
        const { trueName, falseName } = toggleName;
        const currentPerson = this.props.formData.currentDBPerson.node;
        const value = currentPerson[getKeyForPerson(label)];
        return (
            <div className="form-field dbForm" key={index}>
                <div className="field-content"> {label + ':'}</div>
                <div className="field-content field-content-value text-right switch" key={index}>

                    <label>
                        {trueName}
                        <input type="checkbox"
                            onChange={(e) => this.handleFieldsOnChange(e, label)}
                            checked={value === "YES"} />
                        <span className="lever"></span>
                        {falseName}
                    </label>
                </div>
            </div>
        )
    }

    generateResultTitle = () => {
        const { currentDBPerson, currentExcelPerson } = this.props.formData;
        const { index, changed } = currentDBPerson;
        const { firstName, courseID, registeredCourse, ID } = currentDBPerson.node;
        const { possibleMatches, idDBPersonSaved } = currentExcelPerson.node;

        //generate string for title
        let title;
        const saved = "(נשמר)"
        const registered = "רשום לקורס";

        // eslint-disable-next-line
        const isRegistered = registeredCourse.some(course => course.id == courseID); //dose the current person is registerd to the current course
        const isSaved = ID === idDBPersonSaved; //if it was saved earlier
        //generate the html
        if (isRegistered && isSaved) title = <span> <span className="amber-text text-darken-2">{registered}</span> {saved} </span>
        else if (isRegistered) title = <span className="amber-text text-darken-2">{registered}</span>
        else if (isSaved) title = saved;

        return (
            <div className="title title-card">
                <button disabled={index === 0} className="btn navigation" onClick={(e) => { this.handleBtnNavigation(e, -1, changed, firstName) }}><b>{'<'}</b></button>
                <div className="title-continer">
                    <div className="student-title">נמצאו {possibleMatches.length} תוצאות אפשריות בדאטאבייס </div>
                    <div className="page-navigator">
                        <div className="sub-student-title">תוצאה {'#' + (index + 1)} {title} </div>
                    </div>
                </div>
                <button disabled={index === possibleMatches.length - 1} className="btn navigation" onClick={(e) => { this.handleBtnNavigation(e, 1, changed, firstName) }}><b>{'>'}</b></button>
            </div>
        )

    }

    generateNewPersonTitle = (isNewPerson) => {
        const title = isNewPerson ? 'יצירת תלמיד חדש' : 'עדכון תלמיד'  // if its not new person, its registered person.
        const registered = isNewPerson ? '' : <span className="amber-text text-darken-2">רשום לקורס</span>
        return (
            <div className="title title-card">
                <div className="title-continer">
                    <div className="student-title" style={{ opacity: 0 }}>no content</div>
                    <div className="page-navigator">
                        <div className="sub-student-title">{title} {registered}</div>
                    </div>
                </div>
            </div>
        )
    }

    generateActionButtons = () => {
        const { currentExcelPerson, currentDBPerson } = this.props.formData;
        if (currentExcelPerson.node.possibleMatches.length) {
            if (currentDBPerson.newPerson || currentDBPerson.registeredPerson)
                return (
                    <div className="actions right">
                        <button className="btn abort" onClick={(e) => this.handleAbort(e, currentDBPerson.changed, currentExcelPerson.node.firstName)}>בטל וחפש תוצאה אחרת</button>
                    </div>
                )
            return (
                <div className="actions right">
                    <div className="addNew" onClick={(e) => this.handleNewPerson(e, currentDBPerson.changed, currentExcelPerson.node.firstName)}>  הוסף תלמיד חדש »</div>
                </div>
            )
        }
        return null;
    }

    //handles for events
    handleBtnNavigation = (e, direction, currentDBChanged, personExcelName) => {
        e.preventDefault();
        if (currentDBChanged) {
            this.props.updatePopUpConfig('שים לב', [`?לא שמרת את הנתונים עבור ${personExcelName}. האם אתה בטוח שברצונך להמשיך `],
                true, null, () => { this.navigate(direction) }, () => { });
            showPopUp();
        }
        else
            this.navigate(direction);
    }

    navigate = (direction) => {
        const { possibleMatches } = this.props.formData.currentExcelPerson.node; // array of all possible persons matches depends on the current excel person
        const { index, node } = this.props.formData.currentDBPerson;
        const newIndex = index + direction; //1 or -1 => left /right
        if (newIndex >= 0 && newIndex < possibleMatches.length) // bounds
            this.props.updateCurrentDBPerson(
                { // new current person object
                    node: { ...possibleMatches[newIndex], courseID: node.courseID }, //get all new data from the next person and add current selected course
                    index: newIndex,
                    newPerson: false
                })
    }

    handleFieldsOnChange = (e, label) => {
        const currentDbPerson = this.props.formData.currentDBPerson;
        const propKey = getKeyForPerson(label);

        //mailApproval and gender are toggle elements not text type
        const checkInputTypeAndReturnValue = (propKey, e) => {
            switch (propKey) {
                case 'mailApproval'://mailApproval should be YES or NO string. its not a value type input. its checked type.
                    return e.target.checked ? "YES" : "NO";
                case 'gender'://gender should be F or M string. its a value type input but its a radio input type.
                    let value;
                    if (e.target.value === "נקבה") value = "F";
                    if (e.target.value === "זכר") value = "M";
                    return value;
                case 'city':          //city/company its virtual select(we get in 'e' prop an object with label and value anf if we clear input - we get null)
                case 'company':
                    return e ? e.value : ''
                default://all other inputs by default are value type
                    return e.target.value;
            }
        }

        const value = checkInputTypeAndReturnValue(propKey, e);
        let node;
        //if its a phone so its an object , we need to keep it as an object, so we pass non generic node
        if (propKey === "phoneNumber" || propKey === "workPhoneNumber" || propKey === "extraPhone1" || propKey === "extraPhone2")
            node = {
                ...currentDbPerson.node, // all existing data
                [propKey]: { // overide new data and keep the object
                    id: currentDbPerson.node[propKey].id, // same id do not overide him. never! we need it for server if the person is submited
                    value //new data
                }
            }
        else // generic node
            node = {
                ...currentDbPerson.node, // all existing data
                [propKey]: value // overide new data
            }

        const person = {
            ...currentDbPerson, // all existing data
            changed: true, // person was changed - if we navigate popup will apper that we will lose the current unsaved data
            node // the new node with the new updated data
        }
        this.props.updateCurrentDBPerson(person)
    }

    handleNewPerson = (e, currentDBChanged, personExcelName) => {
        e.preventDefault();
        if (currentDBChanged) {
            this.props.updatePopUpConfig('שים לב', [`?לא שמרת את הנתונים עבור ${personExcelName}. האם אתה בטוח שברצונך להמשיך `],
                true, null, this.createNewPerson, () => { });
            showPopUp();
        }
        else
            this.createNewPerson();
    }

    createNewPerson = () => {
        $('.validate').removeClass(["invalid", "valid"]);
        const { index, node } = this.props.formData.currentDBPerson;
        this.props.updateCurrentDBPerson({
            node: {
                ...emptyPersonJsonFormats[this.formType],
                courseID: node.courseID,
            },
            newPerson: true,
            previousIndex: index
        })
    }

    handleAbort = (e, currentDBChanged, personExcelName) => {
        e.preventDefault();
        if (currentDBChanged) {
            this.props.updatePopUpConfig('שים לב', [`?לא שמרת את הנתונים עבור ${personExcelName}. האם אתה בטוח שברצונך להמשיך `],
                true, null, this.AbortNewPerson, () => { });
            showPopUp();
        }
        else
            this.AbortNewPerson();
    }

    AbortNewPerson = () => {
        if (this.state.chosenRegisteredIndex !== "-1")  //if we select from the registers list, we want to init the selected to default
            this.setState({ chosenRegisteredIndex: "-1" })

        $('.validate').removeClass(["invalid", "valid"]);
        const { previousIndex, node } = this.props.formData.currentDBPerson;
        const { possibleMatches } = this.props.formData.currentExcelPerson.node;
        this.props.updateCurrentDBPerson({
            node: {
                ...possibleMatches[previousIndex],
                courseID: node.courseID
            },
            index: previousIndex,
            newPerson: false
        })
    }

    getAllChosenCourseRegisters = (chosenCourseID) => {
        const { courses } = this.props.formData;
        let chosenCourseRegistered = courses.find(course => course.id.toString() === chosenCourseID).registered;
        const defaultOption = chosenCourseRegistered.length ? 'בחר מתוך התלמידים הרשומים לקורס »' : 'לא נמצאו רשומים לקורס';
        return (
            <div className="courseRegistersWrapper">
                <select className="registers-select" value={this.state.chosenRegisteredIndex} readOnly onChange={(e) => this.handleRgistrationList(e, chosenCourseRegistered)}>
                    <option value="-1" disabled>{defaultOption}</option>
                    {
                        chosenCourseRegistered.map((registered, index) => {
                            const { firstName, lastName, email } = registered;
                            return <option value={index} key={index}>{firstName + ' ' + lastName + (email ? (' - ' + email) : '')}</option>
                        })
                    }
                </select>
            </div>
        )
    }

    handleRgistrationList = (e, registeredList) => {
        let registeredIndex = e.target.value;
        let chosenRegisterID = registeredList[registeredIndex].personID;
        const { currentDBPerson } = this.props.formData;
        if (currentDBPerson.changed) {
            this.props.updatePopUpConfig('שים לב', [`?לא שמרת את הנתונים. האם אתה בטוח שברצונך להמשיך `],
                true, null, () => {
                    this.props.getRegisteredDetails(chosenRegisterID);
                    this.setState({ chosenRegisteredIndex: registeredIndex + '' })
                }, this.abortPopUp);

            showPopUp();
        }
        else {
            this.props.getRegisteredDetails(chosenRegisterID);
            this.setState({ chosenRegisteredIndex: registeredIndex + '' });
        }
    }

    abortPopUp = () => {
        let prevSelectedValue = this.state.chosenRegisteredIndex;
        this.setState({ chosenRegisteredIndex: prevSelectedValue });
    }

    generateOptionsSelects = () => {  //generate city and company options elements for select-virtualize element
        const { cities, companies } = this.props.formData;
        this.companyOptions = companies.map((company) => { return { label: company.name, value: company.name } });
        if (this.formType === "opening")
            this.cityOptions = cities.map((city) => { return { label: city.name, value: city.name } });
    }

    componentDidMount() {
        this.generateOptionsSelects();
        var select = document.querySelector('.registers-select');  //init registers list (in case element exist-if not, will be initialized in DidUpdate)
        this.Mselect = M.FormSelect.init(select);
    }

    componentDidUpdate(prevProps) {  //we check if chosen course has changed in parent, if true, we set the chosen register to dfault index(because registes list is changed too)
        var select = document.querySelector('.registers-select');
        if (select) {
            if (!this.Mselect) M.FormSelect.init(select);
            // if client chose some registered from the select list and the chosen courseID has changed or chosen excel person changed,
            // we want to initialize the chosen registered to default.
            if (this.state.chosenRegisteredIndex !== '-1') {
                let PrevCourseID = prevProps.formData.currentDBPerson.node.courseID;
                let currentCourseID = this.props.formData.currentDBPerson.node.courseID;

                let prevIndexExcelPerson = prevProps.formData.currentExcelPerson.index;
                let currentIndexExcelPerson = this.props.formData.currentExcelPerson.index;

                if (PrevCourseID !== currentCourseID || prevIndexExcelPerson !== currentIndexExcelPerson)
                    this.setState({ chosenRegisteredIndex: "-1" });
            }
        }
    }

    render() {
        const { currentDBPerson, currentExcelPerson } = this.props.formData;
        const { node, newPerson, registeredPerson } = currentDBPerson;
        const currentPerson = node;
        const fields = this.formFields[this.formType];
        const firstMatch = currentExcelPerson.node.possibleMatches[0];

        return (
            <div className="card">
                {
                    (newPerson || registeredPerson) ?
                        this.generateNewPersonTitle(newPerson) :
                        this.generateResultTitle()

                }
                <div className="form-field dbForm">
                    <div className="field-content"> {'ID בדאטאבייס:'}</div>
                    <div className="field-content field-content-value text-right"><b>{currentPerson[getKeyForPerson('ID בדאטאבייס')]}</b></div>
                </div>
                {fields && fields.map((field, index) => {
                    let html;
                    switch (field.element) {
                        case "input":
                            html = this.generateInputsElements(field, index)
                            break;
                        case "select":
                            html = this.generateAutoCompleteElements(field, index);
                            break;
                        case "checkbox":
                            html = this.generateToggleElements(field, index)
                            break;
                        default:
                            html = null;
                            break;
                    }
                    return html;
                })
                }
                {(currentPerson.courseID && (!firstMatch || !firstMatch.registeredCourse.length || !firstMatch.registeredCourse.some(course => course.id.toString() === currentPerson.courseID))) ?
                    this.getAllChosenCourseRegisters(currentPerson.courseID) : <></>
                }
                {this.generateActionButtons()}
            </div>
        )
    }

}

const mapStateToProps = (state, ownState) => {
    return {
        formData: state.DB
    }
}

export default connect(mapStateToProps, { updateCurrentDBPerson, updatePopUpConfig, getRegisteredDetails })(DbForm)