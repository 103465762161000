import { CLEAN_ERRORS, REPORT_ERROR } from './types';

export const cleanErrors = () => {
    return { type: CLEAN_ERRORS }
}

export const reportError = (err, dispatch) => {
    let error = err.message;
    if (err.message === "Network Error")
        error = ".אירעה שגיאה בחיבור לשרת, אנא נסה שנית בעוד מספר דקות. לפרטים נוספים פנה למנהל המערכת";
    else if (err.message === "FILE_UPLOAD_ERROR")
        error = ".אירעה שגיאה בהעלאת הקובץ, אנא נסה שנית בעוד מספר דקות. לפרטים נוספים פנה למנהל המערכת";
    else if (err.message === "DB_ERROR")
        error = ".אירעה שגיאה בגישה לנתונים, אנא נסה שנית בעוד מספר דקות. לפרטים נוספים פנה למנהל המערכת"

    dispatch({ type: REPORT_ERROR, payload: error });
}