import { INIT_COURSES_AND_LECTURERES, UPDATE_COURSES, UPDATE_LECTURERS, INIT_REPORT } from '../actions/types';

export default (data = { courses: null, lecturers: null, report: { opinionsGrouByCourses: {} } }, action) => {
    switch (action.type) {
        case INIT_COURSES_AND_LECTURERES: {
            const { courses, lecturers } = action.payload;
            return { ...data, courses, lecturers };
        }
        case UPDATE_COURSES:
            return { ...data, courses: action.payload }
        case UPDATE_LECTURERS:
            return { ...data, lecturers: action.payload }
        case INIT_REPORT:
            return { ...data, report: { opinionsGrouByCourses: action.payload.opinionsGrouByCourses, lecturers: action.payload.lecturers } }
        default:
            return data;
    }
}