import M from 'materialize-css';
import { UPDATE_POPUP_CONFIG } from './types';

export const updatePopUpConfig = (title, messages, confirm, btns, onConfirm, onCancel) => {
    let popUpConfig = {
        title,
        messages,
        confirm,
        btns,
        onConfirm,
        onCancel
    }
    return {
        type: UPDATE_POPUP_CONFIG,
        payload: popUpConfig
    }
}

export const showPopUp = () => {
    var elem = document.getElementById('modal1');
    M.Modal.getInstance(elem).open();
}