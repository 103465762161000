import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { signIn, clearUserNotFoundMsg } from '../../actions/authActions';
import '../../css/loginForm.css';

class Login extends Component {

    renderFormInputs = ({ input, label, type, autoComplete, meta: { touched, error } }) => {
        return (
            <div className="input-field col s6 offset-s3">
                <input type={type} className="validate" autoComplete={autoComplete} {...input} />
                <label>{label}</label>
                {(touched && error) ? <div className="errorMsg">{error}</div> : null}
            </div>
        )
    }

    onSubmit = ({ userName, password }) => {
        this.props.signIn(userName, password);
    }

    render() {
        const { invalid, handleSubmit } = this.props;
        return (
            <div className="row">
                <form onSubmit={handleSubmit(this.onSubmit)} className="col s6 offset-s3 card">
                    <div className="row">
                        <h5 className="center">Login</h5>
                        <Field name="userName" label="User Name" type="text" component={this.renderFormInputs} />
                        <Field name="password" label="Password" type="password" autoComplete="new-password" component={this.renderFormInputs} />
                        <div className="col s6 offset-s5">
                            <br></br>
                            <button disabled={invalid} type="submit" className="btn"> Submit </button>
                        </div>
                        <div className="col s6 offset-s5 errorMsg">
                            <br></br>
                            <h6>{this.props.userNotExistError ? "The name/password you entered did not match our records, for more details please contact the administrator." : ""}</h6>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

const validate = (values, ownProps) => {
    if (ownProps.userNotExistError)
        ownProps.clearUserNotFoundMsg();

    const errors = {};
    if (!values.userName)
        errors.userName = "User name is required";
    if (!values.password)
        errors.password = "Password is required";

    return errors;
}

const mapStateToProps = (state) => {
    return {
        userNotExistError: state.currentUser.userNotExistError
    }
}

const initialValues = {
    userName: '',
    password: ''
}

const EnhancedComponent = compose(
    connect(mapStateToProps, { signIn, clearUserNotFoundMsg }),
    reduxForm({ form: 'loginForm', validate, initialValues })
)
export default EnhancedComponent(Login);