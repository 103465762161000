import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../../helpers/history';
import { getAllOpinions } from '../../../actions/evalReportAction';
import { REPORT_DATES, CHOSEN_COURSE_ID, CHOSEN_LECTURER_ID } from '../../../helpers/sessionKeys';
import '../../../css/EvaluationReport.css';
import * as XLSX from 'xlsx';
import { getLocalFormat, isValid } from '../../../helpers/dateFormats';

class EvaluationReport extends Component {
    asc = false;
    state = {
        hideCourses: false,
        hideLecturers: false,
        courseIndex: -1,   //for hilighting row-column clicked
        lecturerIndex: -1  //for hilighting row-column clicked
    }

    constructor(props) {
        super(props);

        this.courseID = sessionStorage.getItem(CHOSEN_COURSE_ID);
        this.lecturerID = sessionStorage.getItem(CHOSEN_LECTURER_ID);
        this.dates = sessionStorage.getItem(REPORT_DATES);
        if (this.dates) {
            this.dates = JSON.parse(this.dates);
            this.dateViewFormat = { dateFrom: getLocalFormat(this.dates.dateFrom), dateTo: getLocalFormat(this.dates.dateTo) }
        }

        if (!this.props.courses) {
            if (this.dates)
                this.props.getAllOpinions(this.dates.dateFrom, this.dates.dateTo, this.courseID, this.lecturerID);
            else
                history.push("/admin/dateForm_evalReport")
        }

        document.body.style.direction = 'rtl';
        document.body.style.display = 'grid';
    }

    componentDidMount() {
        document.querySelector(".app.container").classList.add("reportWrapper");
    }

    showReportByDateLecturerAndCourse = (courseID, lecturerID) => {
        this.clicked(-1, -1); //if something is hilighted, it will clean it.

        if (courseID && lecturerID) {
            this.courseID = courseID;
            this.lecturerID = lecturerID;
            this.backToPrevReport = true;
            this.props.getAllOpinions(this.dates.dateFrom, this.dates.dateTo, courseID, lecturerID);
        }
    }

    backToFullReport = () => {
        this.courseID = sessionStorage.getItem(CHOSEN_COURSE_ID);
        this.lecturerID = sessionStorage.getItem(CHOSEN_LECTURER_ID);
        this.backToPrevReport = false;
        this.props.getAllOpinions(this.dates.dateFrom, this.dates.dateTo, this.courseID, this.lecturerID);
    }

    sortByLecturersAvg = () => {
        const { lecturers } = this.props;
        this.sort([lecturers], ["lecturerAvg"]);
    }

    sortByCoursesAvg = () => {
        const { courses } = this.props;
        this.sort([courses], ["courseAvg"]);
    }

    sortByName = () => {
        const { courses, lecturers } = this.props;
        let lecturerProp = (Date.parse(lecturers[0].name)) ? Date : String;  //name prop in lecturers array can be name of lecturer or date of course instance
        this.sort([courses, lecturers], ["courseID", "name"], [String, lecturerProp]);
    }

    sort = (arrays, by, types = [Number]) => {
        for (let i = 0; i < arrays.length; i++) {
            let prop = by[i];
            let propType = types[i];
            arrays[i].sort(
                (a, b) => propType === Number ?
                    (this.asc ? (a[prop] - b[prop]) : (b[prop] - a[prop])) : //sort number property
                    (propType === Date ?
                        (this.asc ? sortDate(a, b, prop) : sortDate(b, a, prop)) :  //sort date property
                        (this.asc ? (sortString(a, b, prop)) : (sortString(b, a, prop)))  //sort string property
                    )
            )
        }

        function sortDate(a, b, prop) {
            return (a[prop] < b[prop] ? -1 : (a[prop] > b[prop] ? 1 : 0))
        }

        function sortString(a, b, prop) {
            if (a[prop].toLowerCase() < b[prop].toLowerCase()) return -1;
            if (a[prop].toLowerCase() > b[prop].toLowerCase()) return 1;
            return 0;
        }

        this.forceUpdate();
        this.asc = !this.asc;
    }

    hideCourses = () => {
        let boolState = !this.state.hideCourses;
        this.setState({
            hideCourses: boolState,
            hideLecturers: false
        })
    }

    hideLecturers = () => {
        let boolState = !this.state.hideLecturers;
        this.setState({
            hideLecturers: boolState,
            hideCourses: false
        })
    }

    clicked = (courseIndex, lecturerIndex) => { //when clicking on one of the tds for hilighting row/column
        this.setState({
            courseIndex,
            lecturerIndex
        })
    }

    renderReportDetails = () => {
        const { courses, lecturers } = this.props;
        return <div className="reportDetails">
            <h2 className="center">דוח הערכה למרצים</h2>
            <div className="detailsWrapper">
                <div>
                    <div><label>טווח תאריכים: </label><b>{this.dateViewFormat.dateTo} - {this.dateViewFormat.dateFrom}</b></div>
                    {this.courseID ? <div className="courseName"><b>{courses[0].courseName}</b><label> :קורס</label></div> : <></>}
                    {this.lecturerID ? <div><label>מרצה: </label><b>{this.courseID ? lecturers[0].lecturer : lecturers[0].name}</b></div> : <></>}
                </div>
            </div>
        </div>
    }

    renderNoResult = () => {
        return <div>
            {this.renderReportDetails()}
            <br></br>
            <h5 className="center">אין תוצאות מתאימות</h5>
        </div>
    }

    renderTable = () => {
        const { courses, lecturers } = this.props;
        return <>
            {this.renderReportDetails()}
            <div className="btnWrapper">
                {this.courseID ? <></> : <button className="btn #f57f17 yellow darken-4" onClick={this.hideCourses}>{this.state.hideCourses ? "הצג קורסים" : "הסתר קורסים"}</button>}
                {this.lecturerID ? <></> : <button className="btn #f57f17 yellow darken-4" onClick={this.hideLecturers}>{this.state.hideLecturers ? "הצג מרצים" : "הסתר מרצים"}</button>}
                <button className="btn #f57f17 yellow darken-4" onClick={this.exportToExcel}>יצא לאקסל</button>
            </div>
            <table className="reportTable">
                <thead>
                    <tr>
                        {this.state.hideCourses ?
                            (<>
                                <th className={lecturers.length > 1 ? "cursor hoverColor" : ""} title={lecturers.length > 1 ? "סידור לפי שם" : ""} onClick={this.sortByName}>מרצה</th>
                                <th className={`bottomCol ${lecturers.length > 1 ? 'cursor hoverColor' : ''}`} title={lecturers.length > 1 ? "סידור לפי ממוצע" : ""} onClick={this.sortByLecturersAvg}>ממוצע מרצה</th>
                            </>)
                            :
                            (<>
                                <th className="bottomCol" onClick={this.sortByName}><div className={!this.state.hideLecturers && (courses.length > 1 || lecturers.length > 1) ? "bottomColChild hoverColor cursor" : ""}><span className="hidden">מיון לפי שם</span></div></th>
                                {courses.map((course, index) =>
                                    <th onClick={() => { if (courses.length > 1 && lecturers.length > 1) this.clicked(index, -1) }} className={`hrTitle rotate ${!this.courseID && !this.state.hideLecturers ? 'cursor' : ''}`} title={course.courseName} key={index}>
                                        <div><span className={!this.state.hideLecturers && courses.length > 1 && lecturers.length > 1 ? "hoverColor" : ""}>{course.courseID}</span></div>
                                    </th>
                                )}
                                {!this.courseID ? (!this.state.hideLecturers ? <th className={`bottomCol avgColor ${!this.lecturerID ? "cursor hoverColor" : ""}`} title={!this.lecturerID ? "מיון לפי ממוצע" : ""} onClick={this.sortByLecturersAvg}>ממוצע מרצה</th> : <th className="bottomCol">ממוצע כולל</th>) : (<></>)}
                            </>)
                        }
                    </tr>
                </thead>
                <tbody>
                    {this.state.hideCourses ?
                        lecturers.map((lect, index) =>
                            <tr className="rows" key={index}>
                                <th className="thRight">{lect.name}</th>
                                <td>{lect.lecturerAvg}</td>
                            </tr>)
                        :
                        !this.state.hideLecturers && lecturers.map((lect, lectIndx) =>
                            <tr className="rows" key={lectIndx}>
                                <th onClick={() => { if (courses.length > 1 && lecturers.length > 1) this.clicked(-1, lectIndx) }} className={courses.length > 1 && lecturers.length > 1 ? "thRight hoverColor cursor" : "thRight"}>{this.lecturerNameOrcourseDate(lect)}</th>
                                {courses.map((course, index) => {
                                    let lectureHasCourse = lect[course.courseID];
                                    return <td className={lectIndx === this.state.lecturerIndex || index === this.state.courseIndex ? "hilighted pointerNone" : "pointerNone"} key={index}>
                                    {lectureHasCourse ? 
                                        <>
                                            <b 
                                                title={this.courseID && this.lecturerID ? "" : `לחץ לקבלת דוח מפורט של ${lect.name} בקורס ${course.courseID}`}
                                                className={this.courseID && this.lecturerID ? "" : "lecReport pointer"}
                                                onClick={() => this.showReportByDateLecturerAndCourse(course.courseID, lect.lecturerID)}>{lectureHasCourse.lecturerAvgByCourse}
                                            </b>
                                            <div title={this.courseID && this.lecturerID ? "" :'לחץ כדי לסמן את השורה והעמודה'} className={!this.courseID && !this.lecturerID ? "hlEvent pointer" : ""} onClick={() => this.clicked(index, lectIndx)}>&#10088;
                                                <span title='מספר הציונים שהתקבלו'>{lectureHasCourse.numOfOpinion}</span>/<span title='מספר המשתתפים בקורס'>{lectureHasCourse.numOfStudents}</span>&#10089;
                                            </div>
                                        </> 
                                        : ""}
                                    </td>
                                })}
                                {!this.courseID ? <td className="avgColor"><b>{lect.lecturerAvg}</b></td> : <></>}
                            </tr>)
                    }
                    {
                        !this.lecturerID || (this.lecturerID && this.courseID) ?
                            (!this.state.hideCourses ? (
                                <tr className={!this.state.hideLecturers ? "avgColor" : ""}>
                                    <th className={courses.length > 1 ? "cursor hoverColor" : ""} title={courses.length > 1 ? "מיון לפי ממוצע" : ""} onClick={this.sortByCoursesAvg}>ממוצע קורס</th>
                                    {courses.map((course, index) => <td key={index}><b>{course.courseAvg}</b></td>)}
                                    {!this.lecturerID && !this.courseID ? <td className={!this.state.hideLecturers ? "avgOfAllColor" : "avgColor"}><b>{this.props.avgOfAll}</b></td> : <></>}
                                </tr>
                            ) : (
                                    <tr className="avgColor">
                                        <th>ממוצע כולל</th>
                                        {!this.lecturerID ? <td><b>{this.props.avgOfAll}</b></td> : <></>}
                                    </tr>
                                ))
                            :
                            <></>
                    }

                </tbody>
            </table>
            <br></br>
            <div className="btnWrapper">
                <button className="btn #f57f17 yellow darken-4" onClick={() => history.push('/admin/dateForm_evalReport')}>חזרה לטופס הבחירה</button>
                {this.backToPrevReport ? <button className="btn #f57f17 yellow darken-4" onClick={this.backToFullReport}>חזרה לדוח המלא</button> : <></>}
            </div>
            <div className="comment">ממוצע משוכלל של קורס/מרצה מחושב במעוגל לפי סך כל הציונים שהתקבלו בתקופה</div>
        </>
    }

    lecturerNameOrcourseDate = (lect) => {
        // if lect.name is valid date, it means that lecturers array contain course instance (and not lecturers)
        return isValid(lect.name, 'YYYY-MM-DD') ? getLocalFormat(lect.name) : lect.name
    }

    render() {
        return (
            !this.props.courses ? "Loading" : (!this.props.courses.length ? this.renderNoResult() : this.renderTable())
        )
    }

    exportToExcel = () => {
        const ws = XLSX.utils.table_to_sheet(document.getElementsByClassName("reportTable")[0]);
        let index = 0; //for lecturers array (to replace date format in html to full date format in array)
        for (var row in ws) {
            if (row === "A1") { //first cell of "מיון לפי שם"
                ws[row].v = "";
            }
            if (ws[row]["v"]) {
                if ((ws[row]["v"] + '').includes("❨")) {  //for cells with numbe of opinions/number of students
                    let text = ws[row]["v"];
                    let indxOfBracket = text.indexOf("❨");
                    ws[row]["v"] = text.substring(0, indxOfBracket);
                    let commentText = text.substring(indxOfBracket + 1, text.length - 1);
                    ws[row].c = [{ t: commentText }];
                    ws[row].c.hidden = true;
                }
                else if ((ws[row]["v"] + '').includes("/")) { //for cells with date, but dont get 'z' prop format 'm/d/yy'
                    ws[row]["v"] = new Date(this.props.lecturers[index++].name);
                    ws[row]["z"] = "m/d/yy";
                    ws[row]["t"] = "d";
                }
                else if (ws[row]["z"] === "m/d/yy") { //for cells with date format 'm/d/yy'
                    ws[row]["v"] = new Date(this.props.lecturers[index++].name);
                    ws[row]["t"] = "d";
                }
            }
        }

        const wb = XLSX.utils.book_new();
        wb.Workbook = { Views: [{ RTL: true }] };
        XLSX.utils.book_append_sheet(wb, ws, 'דוח');
        XLSX.writeFile(wb, 'דוח ציונים.xlsx');
    }

    componentWillUnmount() {
        document.body.style.direction = 'unset';
        document.body.style.display = 'unset';
        document.querySelector(".app.container").classList.remove("reportWrapper");
    }
}

const mapStateToProps = ({ reportForm }) => {
    return {
        lecturers: reportForm.report.lecturers,
        courses: reportForm.report.opinionsGrouByCourses.courses,
        avgOfAll: reportForm.report.opinionsGrouByCourses.avgOfAll
    }

}

export default connect(mapStateToProps, { getAllOpinions })(EvaluationReport);