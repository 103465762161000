import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { currentUser, loading, DBNameInfoMsg } from './authReducer';
import error from './errorReducer';
import dbReducer from './dbReducer';
import popUpConfig from './popUpReducer';
import reportForm from './evalReportReducer';

export default combineReducers({
    form: formReducer,
    currentUser,
    DB: dbReducer,
    error,
    popUpConfig,
    loading,
    reportForm,
    DBNameInfoMsg //message to indicate if DB does not fit to the current environment.
}); 