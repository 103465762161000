import React, { Component } from 'react';
import '../../../../../css/OpeningForm.css';
import ExcelForm from '../innerForms/ExcelForm';
import DbForm from '../innerForms/DbForm';


class OpeningForm extends Component {
    render() {
        return (
            <div className="container openingForm">
                <ExcelForm />
                <DbForm />
            </div>
        )
    }
}

export default OpeningForm
