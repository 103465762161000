import http from '../api/server';
import { INIT_COURSES_AND_LECTURERES, UPDATE_COURSES, UPDATE_LECTURERS, INIT_REPORT, SIGN_OUT } from './types';
import history from '../helpers/history';
import { reportError } from './errorActions';

export const getAllCoursesByDates = (from, to) => {
    return (dispatch) => {
        http.get("/getCoursesByDates", { params: { from: from, to: to } }).then(res => {
            dispatch({ type: UPDATE_COURSES, payload: res.data })
        }, error => {
            if (error.response) {
                if (error.response.status === 401) {
                    dispatch({ type: SIGN_OUT });
                }
                else if (error.response.status === 404) {
                    error.message = "DB_ERROR";
                    reportError(error, dispatch);
                }
            }
            else {
                reportError(error, dispatch);
            }
        })
    }
}

export const getAllLecturersByDates = (from, to) => {
    return (dispatch) => {
        http.get("/getLecturersByDates", { params: { from: from, to: to } }).then(res => {
            dispatch({ type: UPDATE_LECTURERS, payload: res.data })
        }, error => {
            if (error.response) {
                if (error.response.status === 401) {
                    dispatch({ type: SIGN_OUT });
                }
                else if (error.response.status === 404) {
                    error.message = "DB_ERROR";
                    reportError(error, dispatch);
                }
            }
            else {
                reportError(error, dispatch);
            }
        })
    }
}

export const initCoursesAndLecturers = (from, to, courseID, lecturerID) => {
    return (dispatch) => {
        http.get("/initCoursesAndLecturers", { params: { from: from, to: to, courseID: courseID, lecturerID: lecturerID } }).then(res => {
            dispatch({ type: INIT_COURSES_AND_LECTURERES, payload: res.data })
        }, error => {
            if (error.response) {
                if (error.response.status === 401) {
                    dispatch({ type: SIGN_OUT });
                }
                else if (error.response.status === 404) {
                    error.message = "DB_ERROR";
                    reportError(error, dispatch);
                }
            }
            else {
                reportError(error, dispatch);
            }
        })
    }
}

export const getLecturersByCourse = (from, to, courseID) => {
    return (dispatch) => {
        http.get("/getLecturersByCourse", { params: { from: from, to: to, courseID: courseID } }).then(res => {
            dispatch({ type: UPDATE_LECTURERS, payload: res.data })
        }, error => {
            if (error.response) {
                if (error.response.status === 401) {
                    dispatch({ type: SIGN_OUT });
                }
                else if (error.response.status === 404) {
                    error.message = "DB_ERROR";
                    reportError(error, dispatch);
                }
            }
            else {
                reportError(error, dispatch);
            }
        })
    }
}

export const getCoursesByDatesAndLecturer = (from, to, lecturerID) => {
    return (dispatch) => {
        http.get("/getCoursesByDatesAndLecturer", { params: { from: from, to: to, lecturerID: lecturerID } }).then(res => {
            dispatch({ type: UPDATE_COURSES, payload: res.data })
        }, error => {
            if (error.response) {
                if (error.response.status === 401) {
                    dispatch({ type: SIGN_OUT });
                }
                else if (error.response.status === 404) {
                    error.message = "DB_ERROR";
                    reportError(error, dispatch);
                }
            }
            else {
                reportError(error, dispatch);
            }
        })
    }
}

export const getAllOpinions = (from, to, courseID, lecturerID) => {
    return dispatch => {
        http.get("/getAllOpinionsByDate", { params: { from: from, to: to, courseID: courseID, lecturerID: lecturerID } }).then(res => {
            dispatch({ type: INIT_REPORT, payload: res.data });
            if (history.location.pathname !== "/admin/evalReport") //this api is called in both dateform report and the report itself.
                history.push("/admin/evalReport");
        }, error => {
            if (error.response) {
                if (error.response.status === 401) {
                    dispatch({ type: SIGN_OUT });
                }
                else if (error.response.status === 404) {
                    error.message = "DB_ERROR";
                    reportError(error, dispatch);
                }
            }
            else {
                reportError(error, dispatch);
            }
        })
    }
}