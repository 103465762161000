import React, { Component } from 'react';
import '../../../../../css/EvaluationForm.css';
import ExcelForm from '../innerForms/ExcelForm';
import DbForm from '../innerForms/DbForm';
import { connect } from 'react-redux';

class EvaluationForm extends Component {

    render() {
        const {courseGrade,materialsGrade,lecturerGrade} = this.props.currentExcelPerson
        return (
            <div className="evaluationForm">
                <div className="forms">
                    <ExcelForm />
                    <DbForm />
                </div>
                <div className="grades">
                    <label htmlFor="">ציונים</label>
                    <div className="gardesList">
                        <div className="item">מרצה: <span className="gradeNum">{lecturerGrade}</span></div>
                        <div className="item">חומר לימוד:  <span className="gradeNum">{materialsGrade}</span></div>
                        <div className="item">קורס:  <span className="gradeNum">{courseGrade}</span></div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentExcelPerson: state.DB.currentExcelPerson.node
    }
}

export default connect(mapStateToProps)(EvaluationForm)