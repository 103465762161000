import React, { Component } from 'react';
import M from 'materialize-css';
import { getSQLFormat } from './dateFormats';

class DatesPicker extends Component {
    state = {
        datesInvalidErrorMsg: null
    }

    componentDidMount() {
        let sessionDates = sessionStorage.getItem(this.props.sessionKey);
        this.elems = document.querySelectorAll('.datepicker');
        this.dates = [];

        if (!sessionDates)
            this.initDatePickerWithDates();
        else {
            sessionDates = JSON.parse(sessionDates);
            this.initDatePickerWithDates(new Date(sessionDates.dateFrom), new Date(sessionDates.dateTo))
        }
        let dateFrom = this.dates[0].date;
        let dateTo = this.dates[1].date;
        this.props.onInit(this.sendDateObj(dateFrom, dateTo));
    }

    onDateChange = () => {
        let dateFrom = this.dates[0].date;
        let dateTo = this.dates[1].date;

        let difference = this.datesDiffInDays(dateFrom, dateTo);
        //only dateForm of evaluation/opening pass prop of range (report dates dont need range)
        if (difference >= 0 && (!this.props.validRange || difference <= this.props.validRange)) {
            this.setState({
                datesInvalidErrorMsg: null
            })
            this.props.onInit(this.sendDateObj(dateFrom, dateTo));
            this.props.onDatesChange(true);
        }
        else {
            let errorMsg;
            if (difference < 0) errorMsg = "טווח התאריכים אינו תקין";
            else errorMsg = "טווח התאריכים אינו יכול לעלות על הפרש של " + this.props.validRange + " ימים"

            this.setState({
                datesInvalidErrorMsg: errorMsg
            })

            this.props.onDatesChange(false);
        }

    }

    initDatePickerWithDates = (dateFrom = new Date(), dateTo = new Date()) => {
        var currentYear = new Date().getFullYear();
        this.dates[0] = M.Datepicker.init(this.elems[0], { format: 'dd/mm/yyyy', yearRange: [2000, currentYear], defaultDate: dateFrom, setDefaultDate: true, onClose: (date) => this.onDateChange(date) });
        this.dates[1] = M.Datepicker.init(this.elems[1], { format: 'dd/mm/yyyy', yearRange: [2000, currentYear], defaultDate: dateTo, setDefaultDate: true, onClose: (date) => this.onDateChange(date) });
    }

    setDatesAfterChangeFile = (date) => {  //parent form call this function with excel extracted date, in order to change default current date.
        this.initDatePickerWithDates(date, date);
        this.onDateChange();
    }

    datesDiffInDays = (dateFrom, dateTo) => {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;

        const utc1 = Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate());
        const utc2 = Date.UTC(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    sendDateObj = (dateFrom, dateTo) => { //send changed dates to parent (using onInit prop function)
        return { dateFrom: getSQLFormat(dateFrom), dateTo: getSQLFormat(dateTo) }
    }

    render() {
        return (
            <div className="datePickerComponent center">
                <label>בחר טווח תאריכים בהם התקיים הקורס: </label><br></br>
                <label> החל מ </label><input type="text" id="dateTo" className="datepicker" />
                <label>עד</label><input type="text" id="dateFrom" className="datepicker" /><br></br>
                {this.state.datesInvalidErrorMsg ? <div className="errorMsg">{this.state.datesInvalidErrorMsg}</div> : ""}
            </div>)
    }


}

export default DatesPicker;
