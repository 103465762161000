import React, { Component } from 'react';
import '../../../../css/FormLayout.css';
import OpeningForm from './openingForm/OpeningForm';
import EvaluationForm from './evaluationForm/EvaluationForm';
import { EXCEL_PERSONS, DATES, FORM_TYPE } from '../../../../helpers/sessionKeys';
import { connect } from 'react-redux';
import { initData, updateCurrentDBPerson, submitCurrentDBPerson, updateCurrentExcelPerson, emptyForm, updateCurrentCourse } from '../../../../actions/formsActions';
import { showPopUp, updatePopUpConfig } from '../../../../actions/popupActions';
import M from 'materialize-css';

export class FormLayout extends Component {

    state = {
        loading: false
    }

    componentDidMount() {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems)

        let dates = JSON.parse(sessionStorage.getItem(DATES));
        if (!dates)
            this.props.history.push('/admin/dateForm/opening');
        else {
            let excelPerson = JSON.parse(sessionStorage.getItem(EXCEL_PERSONS));
            this.formType = sessionStorage.getItem(FORM_TYPE); //opening or evaluation
            this.props.initData(excelPerson, dates.dateFrom, dates.dateTo, this.formType);
        }
    }

    //we initialize students and courses select element(cause in the first render -DidMount- we dont have this elements in the dom)
    componentDidUpdate() {
        var elems = document.querySelectorAll('#courseDrop select, .studentList select');
        M.FormSelect.init(elems);
    }

    componentWillUnmount() {
        this.props.emptyForm();
    }

    generateSelect = (label, dataArray, value = "-1") => {
        return (
            <select value={value} readOnly>
                <option value="-1" disabled>  {label}</option>
                {
                    dataArray && dataArray.map((option, index) => {
                        return <option value={option.value} key={index}>{option.name}{option.saved ? ' (נשמר) ' : ''}</option>
                    })
                }
            </select>
        )
    }

    countChangedPersons = () => {
        const { personsFromExcel } = this.props.formData;
        let count = 0;
        for (let index = 0; index < personsFromExcel.length; index++) {
            if (personsFromExcel[index].saved) count++;
        }
        return count;
    }

    mapPersonsFromExcel = () => {
        return this.props.formData.personsFromExcel.map((person, index) => {
            let name = "לא צויין שם";
            if (person.firstName || person.lastName)
                name = (person.firstName ? person.firstName : "") + " " + (person.lastName ? person.lastName : "");
            return {
                name: name,
                value: index,
                saved: person.saved
            }
        })
    }

    mapCourses = () => {
        return this.props.formData.courses.map((course) => {
            return {
                name: `${course.tCourseID} - ${course.name} - ${course.startDate}${course.endDate ? (' - ' + course.endDate) : ''}, Cycle ${course.cycle} ${course.isPublic === "V" ? " , " + course.company : ""}`,
                value: course.id
            }
        })
    }

    handleCourses = (e, currentDBPerson, personName) => {
        let courseID = e.target.value;
        if (currentDBPerson.changed) {
            this.props.updatePopUpConfig('שים לב', [`?לא שמרת את הנתונים עבור ${personName}. האם אתה בטוח שברצונך להמשיך `],
                true, null, () => { this.props.updateCurrentCourse(courseID) }, this.abortPopUp);
            showPopUp();
        }
        else
            this.props.updateCurrentCourse(courseID)
    }

    handleStudentList = (e, currentDBPerson, personName) => {
        let personIndex = parseInt(e.target.value);
        if (currentDBPerson.changed) {
            this.props.updatePopUpConfig('שים לב', [`?לא שמרת את הנתונים עבור ${personName}. האם אתה בטוח שברצונך להמשיך `],
                true, null, () => { this.props.updateCurrentExcelPerson(personIndex) }, this.abortPopUp);

            showPopUp();
        }
        else
            this.props.updateCurrentExcelPerson(personIndex);
    }

    abortPopUp = () => {
        let prevSelectedValue = document.querySelector('.studentList select').value;
        let prevTextInput = document.querySelector(`.studentList select option[value="${prevSelectedValue}"]`).text;
        document.querySelector('.studentList .select-dropdown.dropdown-trigger').value = prevTextInput;
    }

    handelSubmit = (e, index) => {
        e.preventDefault();
        const result = this.validateForm();
        if (result.valid) {
            this.setState({ loading: true })

            //callback for the button loding status and error from server.
            const whenDone = (err = null) => {
                this.setState({ loading: false })

                if (err) {
                    const errorMessage = err.TransactionError ? "Transaction Error" : err ? err : 'something went wrong';
                    this.props.updatePopUpConfig('אירעה שגיאה בשרת', [".אירעה שגיאה בשליחת הנתונים, אנא נסה שנית בעוד מספר דקות. לפרטים נוספים פנה למנהל המערכת", `Error: ${errorMessage}`], false, { close: "אשר" });
                    showPopUp();
                }
                else if (index === this.props.formData.personsFromExcel.length) {
                    this.props.updatePopUpConfig('התלמיד האחרון ברשימה נשמר בהצלחה', ['לחזרה לעמוד הראשי להעלאת קובץ חדש לחץ <b>חזור</b>,&nbsp להמשך פעילות בדף הנוכחי לחץ <b>ביטול</b>'], true, { confirm: 'חזור', unConfirm: 'ביטול' }, this.exit, () => { });
                    showPopUp();
                }
            }

            this.props.submitCurrentDBPerson(++index, this.formType, whenDone);
        }
        else {
            this.props.updatePopUpConfig('טופס לא תקין', result.errorMessages, false, { close: "אשר" });
            showPopUp();
        }
    }

    validateForm() {
        const { node, newPerson } = this.props.formData.currentDBPerson;
        const { courseID, firstName, lastName, phoneNumber, workPhoneNumber, extraPhone1, extraPhone2 } = node;
        // const { companies, cities } = this.props.formData;
        let gender = node.gender;
        //if its not new person so we dont have gender - make it true
        if (!newPerson) gender = true;

        //if all required fields are filled
        if (courseID && firstName && lastName && gender) {
            let phonesValid;
            let messages = [] // array of errors to display in popup

            //validate the phones
            const tmpPhones = [phoneNumber, workPhoneNumber, extraPhone1, extraPhone2]
            const phones = tmpPhones.filter(phone => phone && phone.value)
            if (phones.length) {
                for (let index = 0; index < phones.length; index++) {
                    const value = phones[index].value;
                    if (value) {
                        // we check if the value is a correct format of the phone format we have in db. exmple for correct format (Israel): 03-7885483 or 054-6733324
                        const cellRegionCode = '0([0-9][0-9])' //cellphone
                        const phoneRegionCode = '0([0-9])' //phone
                        // eslint-disable-next-line
                        const numberRegx = '[^0\\D]{1}\\d{6}' // check 7 digits
                        // eslint-disable-next-line
                        const phoneNumberRegx = new RegExp(`^${phoneRegionCode}{1}-${numberRegx}$`, 'g');
                        // eslint-disable-next-line
                        const cellNumberRegx = new RegExp(`^${cellRegionCode}{1}-${numberRegx}$`, 'g');
                        phonesValid = value.match(phoneNumberRegx) || value.match(cellNumberRegx);

                        if (!phonesValid) {
                            if (!value.includes('-'))
                                messages.push('.מספר הטלפון חייב להיות עם מקף בין הקידומת למספר עצמו');
                            else
                                messages.push('.מספר הטלפון חייב להיות באורך של 7 ספרות, ועוד קידומת שאורכה בין 2-3 ספרות, המתחילה ב-0');
                            break;
                        }
                    }
                }
            } else phonesValid = true; //if no phones where filled so the validation is passed

            //if we passed all validations
            if (phonesValid) return { valid: true };
            else return { valid: false, errorMessages: messages }; //if not return error messages for popup
        }
        else { //required fields that not filled - courseID has its own validation
            let requiredErrors = [':על מנת לשמור תלמיד יש למלא קודם את שדות החובה'];
            if (!firstName) requiredErrors.push(".שם פרטי");
            if (!lastName) requiredErrors.push(".שם משפחה");
            if (!gender) requiredErrors.push(".מין");
            return { valid: false, errorMessages: requiredErrors };
        }
    }

    handleExitBtn = (e, currentDBPerson, personExcelName) => {
        e.preventDefault();
        if (currentDBPerson.changed) {
            this.props.updatePopUpConfig('שים לב', [`?לא שמרת את הנתונים עבור ${personExcelName}. האם אתה בטוח שברצונך לצאת `],
                true, null, this.exit, () => { });

            showPopUp();
        }
        else
            this.exit();
    }


    exit = () => {
        if (this.formType === "opening")
            this.props.history.push("/admin/dateForm/opening");
        else
            this.props.history.push("/admin/dateForm/evaluation");
    }

    render() {
        if (!this.props.formData) return <div>Loading...</div>
        else {
            const { node } = this.props.formData.currentExcelPerson;
            const { personsFromExcel, currentExcelPerson, currentDBPerson, courses } = this.props.formData;
            const numOfStudentsImported = personsFromExcel.length;
            const numOfStudentsChanged = this.countChangedPersons();
            const mappedStudentArray = this.mapPersonsFromExcel();
            const mappedCoursesArray = this.mapCourses();
            const personToSubmit = currentDBPerson.node;
            // eslint-disable-next-line
            let chosenCourse = courses.find(course => course.id == personToSubmit.courseID);
            chosenCourse = chosenCourse ? chosenCourse.name : null;

            const getButtonSubmitStatus = () => {
                if (personToSubmit.courseID) {
                    if (this.state.loading) return "שומר אנא המתן..."
                    else {
                        if (currentExcelPerson.index === personsFromExcel.length - 1)
                            return "שמור"
                        return "שמור והתקדם לתלמיד הבא"
                    }
                }
                return "אנא בחר קורס על מנת לשמור"
            }

            return (
                <form className="formLayout card-panel" autoComplete="off">
                    <h3 className="center">עדכון והוספת פרטים</h3>
                    <div className="flex-3-inline">
                        <div className="right">מספר התלמידים שיובאו מהאקסל: {numOfStudentsImported} </div>
                        <div onChange={(e) => this.handleStudentList(e, currentDBPerson, node.firstName)} className="studentList">
                            {
                                this.generateSelect("אנא בחר תלמיד", mappedStudentArray, currentExcelPerson.index)
                            }
                        </div>
                        <div>מספר התלמידים בהם נעשו שינויים עד כה: {numOfStudentsChanged}</div>
                    </div>

                    <hr />
                    <div id="coursesContainer">
                        <div onChange={(e) => { this.handleCourses(e, currentDBPerson, node.firstName) }} className="input-field" id="courseDrop">
                            {this.generateSelect(mappedCoursesArray.length ? "אנא בחר את הקורס שאליו התייחס הטופס" : "לא נמצאו קורסים בתאריכים שנבחרו", mappedCoursesArray, personToSubmit.courseID)}
                        </div>
                    </div>


                    {/*overlay - can be disabled*/}
                    <div className="overlay-container">
                        {
                            !personToSubmit.courseID &&
                            <div className="overlay-message">אנא בחר קורס על מנת להתחיל לבצע שינויים בפרטי התלמיד</div>
                        }

                        {/*show inner forms if courseID is chosen*/}
                        <div className={!personToSubmit.courseID ? "blur" : ""}>
                            {this.formType === 'opening' ? <OpeningForm /> : <EvaluationForm />}
                        </div>
                    </div>


                    <div className=" courseCompare">
                        <div className="ccCourse">
                            <span> הקורס שבחרת:</span>
                            <span>
                                <b className="courseWritten">{chosenCourse}</b>
                            </span>
                        </div>
                        <br></br>
                        <div className="ccStudent">
                            <span> התלמיד רשם:</span>
                            <span>
                                <b className="courseWritten">{node.course}</b>
                            </span>
                        </div>
                    </div>

                    {/*                 <div className="flex-2-inline  studentWrote">
                    <div className="flex-2-inline-item left" style={{ 'paddingRight': '2%' }}>
                        <span> :קורס שנבחר</span>
                        <span>
                            <b id="courseWritten">{chosenCourse}</b>
                        </span>
                    </div>
                    <div className="flex-2-inline-item right">
                        <span> :תלמיד רשם</span>
                        <span>
                            <b id="courseWritten">{node.course}</b>
                        </span>
                    </div>
                </div> */}
                    <button disabled={!personToSubmit.courseID || this.state.loading} className="btn submit" onClick={(e) => this.handelSubmit(e, currentExcelPerson.index)}>
                        {
                            getButtonSubmitStatus()
                        }
                        <i className="material-icons left">chevron_left</i>
                    </button>
                    <button className="btn exit" onClick={(e) => this.handleExitBtn(e, currentDBPerson, node.firstName)}>חזור</button>
                </form >
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        formData: state.DB
    }
}

//maping the user model to form - a general model of person (incluts all the fields-props in both of the forms***opening/evaluation***)
export const getKeyForPerson = (labelName) => {
    let personMaping = {
        "ID בדאטאבייס": "ID",
        "שם פרטי": "firstName",
        "שם משפחה": "lastName",
        "פרטי באנגלית": "firstNameEnglish",
        "משפחה באנגלית": "lastNameEnglish",
        "טלפון סלולרי": "phoneNumber",
        "טלפון עבודה": "workPhoneNumber",
        "אימייל": "email",
        "רחוב": "street",
        "עיר": "city",
        "חברה": "company",
        "מאשר קבלת דואר": "mailApproval",
        "מין": "gender",
        "כתובת למשלוח": "address",
        "טלפון נוסף 1": "extraPhone1",
        "טלפון נוסף 2": "extraPhone2",
    }
    return personMaping[labelName];
}

export default connect(mapStateToProps, {
    //funcs add to props
    initData,
    updateCurrentDBPerson,
    submitCurrentDBPerson,
    updateCurrentExcelPerson,
    emptyForm, updatePopUpConfig,
    updateCurrentCourse
})(FormLayout)
