import {
    INIT_DATA,
    UPDATE_CURRENT_DB_FORM,
    UPDATE_CURRENT_EXCEL_FORM,
    SIGN_OUT,
    SUBMIT_CURRENT_DB_FORM,
    SUBMIT_CURRENT_DB_FORM_ERROR,
    EMPTY_FORM,
    UPDATE_CURRENT_COURSE
} from './types';
import { FORM_TYPE } from '../helpers/sessionKeys';
import http from '../api/server';
import { reportError } from './errorActions';


export const initData = (personsFromExcelJson, dateFrom, dateTo, formType) => {
    return async (dispatch) => {
        try {
            const res = await http.post('/initData', { personsFromExcel: personsFromExcelJson, dateFrom, dateTo, formType });

            const { personsFromExcel } = res.data;
            const firstPersonToDisplay = personsFromExcel[0];

            //init currentExcelPerson into data - this person will be shown in ExcelForm - innerForms (evaluation/opening)
            res.data["currentExcelPerson"] = { node: '', index: '' }
            res.data["currentExcelPerson"].node = firstPersonToDisplay;
            res.data["currentExcelPerson"].index = 0;


            //init currentDBPerson into data - this person will be shown in DbForm - innerForms (evaluation/opening)
            res.data["currentDBPerson"] = { node: '', index: '', newPerson: false };

            if (firstPersonToDisplay.possibleMatches.length) {
                res.data["currentDBPerson"]["node"] = firstPersonToDisplay.possibleMatches[0];
            }
            else {
                //no match , we need to init empty person template that the form will know to render any empty form (new student)
                res.data["currentDBPerson"].node = emptyPersonJsonFormats[formType];
                res.data["currentDBPerson"].newPerson = true
            }
            res.data["currentDBPerson"].index = 0;

            dispatch({ type: INIT_DATA, payload: res.data })

        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    dispatch({ type: SIGN_OUT });
                }
                else if (error.response.status === 404) {
                    error.message = "DB_ERROR";
                    reportError(error, dispatch);
                }
            }
            else {
                reportError(error, dispatch);
            }
        }

    }
}

export const emptyForm = () => {
    return (dispatch) => {
        dispatch({ type: EMPTY_FORM })
    }
}

export const updateCurrentDBPerson = (person) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_CURRENT_DB_FORM, payload: person })
    }
}

export const updateCurrentExcelPerson = (index) => {
    return (dispatch, getState) => {
        dispatch({ type: UPDATE_CURRENT_EXCEL_FORM, payload: getExcelPersonByIndex(index, getState().DB) })
    }
}

export const updateCurrentCourse = (courseID) => {
    return (dispatch, getState) => {
        const { DB } = getState();
        const { currentExcelPerson, currentDBPerson } = DB;
        const { possibleMatches } = currentExcelPerson.node;
        if (currentDBPerson.newPerson) {
            let newPerson = { ...currentDBPerson };
            newPerson.node["courseID"] = courseID;
            dispatch({ type: UPDATE_CURRENT_EXCEL_FORM, payload: newPerson })
        }
        else {
            const orderdpossibleMatches = possibleMatches.length ? reorderPossibleMatchesByRegistration(courseID, possibleMatches) : [];
            let newCurrentExcelPerson = { ...currentExcelPerson }; //make a copy
            newCurrentExcelPerson["node"]["possibleMatches"] = orderdpossibleMatches; //override only the possibleMatches to orderd possibleMatches
            const newCurrentDBPerson = getSavedOrFirstMatch(orderdpossibleMatches, currentExcelPerson, courseID); // get new match

            dispatch({ type: UPDATE_CURRENT_COURSE, payload: { currentDBPerson: newCurrentDBPerson, currentExcelPerson: newCurrentExcelPerson } })
        }
    }
}

export const submitCurrentDBPerson = (index, formType, whenHTTPDoneCallback) => {
    return (dispatch, getState) => {
        let { DB } = getState();
        let { currentDBPerson } = DB;
        //we will make an http post request only if: DBPerson has some changes/ person is not registered to chosen course/ we are in evaluation form (need to insert opinions to DB)
        if (formType === 'evaluation' || currentDBPerson.changed || !currentDBPerson.node.registeredCourse.some(course => course.id.toString() === currentDBPerson.node.courseID)) {
            http.post('/postPerson', makeCopyAndSetDBPerson(DB, formType))
                .then(
                    res => {
                        dispatch({
                            type: SUBMIT_CURRENT_DB_FORM,
                            payload: {
                                personsFromExcel: makeUpdatedPersonsFromExcel(DB, res["data"].person),
                                ...getExcelPersonByIndex(index, DB)
                            }
                        })
                        whenHTTPDoneCallback();
                    },
                    err => {
                        dispatch({ type: SUBMIT_CURRENT_DB_FORM_ERROR });
                        if (err.response) whenHTTPDoneCallback(err.response["data"]);
                        else whenHTTPDoneCallback("503 - server is down ");
                    }
                )
        }
        else {
            dispatch({
                type: SUBMIT_CURRENT_DB_FORM,
                payload: {
                    personsFromExcel: makeUpdatedPersonsFromExcel(DB),
                    ...getExcelPersonByIndex(index, DB)
                }
            })
            whenHTTPDoneCallback();
        }
    }
}

const makeCopyAndSetDBPerson = ({ currentDBPerson, currentExcelPerson, companies, courses }, formType) => {
    //currentDBPerson befor chnages
    let oldDBPerson = currentExcelPerson.node.possibleMatches[currentDBPerson.index];
    //copy of the currentDBPerson after changes that we gona change his mailApproval's key to an object with newRecord and value props
    let newDBPerson = { ...currentDBPerson.node };

    newDBPerson["newPerson"] = currentDBPerson.newPerson;
    newDBPerson["registeredPerson"] = currentDBPerson.registeredPerson;
    newDBPerson["changed"] = currentDBPerson.changed;
    //if its new/registered person, we dont have an oldDBPerson from matches-so, newPerson or mailApproval==='' means creating new email record (mailApproval === '' => no records in database - create new record )
    if (currentDBPerson.newPerson || (currentDBPerson.registeredPerson && currentDBPerson.node.mailApproval === "") || (oldDBPerson && oldDBPerson.mailApproval === ""))
        newDBPerson.mailApproval = {
            newRecord: true, //we need to create new record - flag for server
            //if its false it means it is new person and the toggle wasnt touched else its YES/NO/UNKN/"" string, so if its not approved(YES) we will save it as "NO" 
            value: newDBPerson.mailApproval === "YES" ? "YES" : "NO"
        }
    else newDBPerson.mailApproval = { newRecord: false, value: newDBPerson.mailApproval === "YES" ? "YES" : "NO" }

    //set the person with an object contains id of the company (cuz current company has only string value)
    if (newDBPerson["company"])
        newDBPerson["company"] = companies.find(company => company.name === newDBPerson["company"]);

    //set the person with an object contains data about the chosen course (cuz current courseID has only int value)
    // eslint-disable-next-line
    newDBPerson["course"] = courses.find(course => course.id == newDBPerson["courseID"]);
    newDBPerson["formType"] = formType;
    delete newDBPerson["courseID"];

    //in evaluation we add the grades to be updated in the db
    if (formType === "evaluation") {
        const { courseGrade, materialsGrade, lecturerGrade } = currentExcelPerson.node
        newDBPerson["courseGrade"] = courseGrade;
        newDBPerson["materialsGrade"] = materialsGrade;
        newDBPerson["lecturerGrade"] = lecturerGrade;
    }

    return newDBPerson;
}

const makeUpdatedPersonsFromExcel = ({ currentDBPerson, currentExcelPerson, personsFromExcel }, response = null) => {
    //we use this for the response, if no respose passed, we will use the currentDBPerson (no response when there is no http request-see in 'submitCurrentDBPerson')
    let DBPerson = { ...currentDBPerson }; //copy 

    //if its was call from submit we use the res - its the person we just saved, if its new person it will contain the new ID from db
    if (response) {
        DBPerson.node = response;
        //converting the company and course back to strings - in setNewDBPerson() we make them objects
        if (DBPerson.node["company"])
            DBPerson.node["company"] = DBPerson.node["company"].name
        DBPerson.node["courseID"] = DBPerson.node["course"].id
        delete DBPerson.node["course"];
    }
    //make a copy to override him
    let updatedPersonsFromExcel = [...personsFromExcel];
    //indicator for saved person (from excel) - we want to display who changed already when navigating
    updatedPersonsFromExcel[currentExcelPerson.index].saved = true;


    if (!DBPerson.newPerson && !DBPerson.registeredPerson) {
        updatedPersonsFromExcel[currentExcelPerson.index].possibleMatches[DBPerson.index] = DBPerson.node; //if its not an new person/registered(not from matches), just modify the existing
    }
    else {
        //if its new person we need to add him to the array of possible maches
        //cuz we wont refresh the page - refreshing will init new (updated) data from database, therefore we need to update the data manualy
        updatedPersonsFromExcel[currentExcelPerson.index].possibleMatches.push(DBPerson.node);
    }
    updatedPersonsFromExcel[currentExcelPerson.index].idDBPersonSaved = DBPerson.node.ID;

    return updatedPersonsFromExcel;
}

export const getExcelPersonByIndex = (index, { personsFromExcel, currentDBPerson }) => {
    if (index >= personsFromExcel.length) index -= 1; //if we arrive to the end of excel person list, we dont go to next person.

    const { possibleMatches } = personsFromExcel[index];
    const { courseID } = currentDBPerson.node //courseID may be empty
    const orderdPossibleMatches = possibleMatches.length ? reorderPossibleMatchesByRegistration(courseID, possibleMatches) : []

    let newCurrentExcelPerson = {
        node: {
            ...personsFromExcel[index],
            //when we submit or navigate between excel persons we need to order by registration.
            possibleMatches: orderdPossibleMatches
        },
        index
    };

    let newCurrenDBPerson;
    if (possibleMatches.length)
        newCurrenDBPerson = getSavedOrFirstMatch(orderdPossibleMatches, personsFromExcel[index], courseID)
    else { //no matches so we make new person - meaning new clean DbForm 
        let formType = sessionStorage.getItem(FORM_TYPE);
        if (!formType) throw Error("getExcelPersonByIndex must have formType in session that is not null or undefined")
        newCurrenDBPerson = {
            node: emptyPersonJsonFormats[formType],
            index: 0,
            newPerson: true
        }
        if (courseID) newCurrenDBPerson.node["courseID"] = courseID
    }
    return { currentDBPerson: newCurrenDBPerson, currentExcelPerson: newCurrentExcelPerson }
}

//algoritem to get the best match for the excel person- get match which saved and if there is no saved, we will get first match(best match by reorderPossibleMatchesByRegistration) 
const getSavedOrFirstMatch = (possibleMatchesArray, objToMatch, courseID) => {
    let index = 0;
    if (objToMatch.idDBPersonSaved)
        index = possibleMatchesArray.findIndex(DBPerson => DBPerson.ID === objToMatch.idDBPersonSaved); //if person already changed and saved, we want to jump to the saved possibleMatch.
    return {
        node: {
            ...possibleMatchesArray[index],
            courseID // in order to save chosen courseID for all excel person through navigation between them or when chosen course is changed/first time chosen.
        },
        index: index,
        newPerson: false
    }
}

const reorderPossibleMatchesByRegistration = (courseID, possibleMatches) => {
    //check for each person if he is registered to the current chosen course 
    //we split the result to 2 arrays (registart or not) later we will join them back (they alredy sorded by score)
    //if no registerd matches to this course we will sort the possibleMatches by score (like in server)
    let registerdMatches = [];
    let otherMatches = [];
    const totalMatches = possibleMatches.length;
    for (let index = 0; index < possibleMatches.length; index++) {
        //check if has any registerd courses
        const registeredCourses = possibleMatches[index].registeredCourse.length ? possibleMatches[index].registeredCourse : null;
        // eslint-disable-next-line
        if (registeredCourses && registeredCourses.some(course => course.id == courseID)) { //check if one of the registerd courses is matching to the current chosen course
            registerdMatches.push(possibleMatches[index]); //add it to the registerd array
        } else otherMatches.push(possibleMatches[index]); //no registartion or no maching add to others array
    }
    if (otherMatches.length === totalMatches) //this result means no registration has founded
        return possibleMatches.sort((a, b) => (a.score > b.score) ? -1 : ((b.score > a.score) ? 1 : 0)) //sort by score

    return registerdMatches.concat(otherMatches); // combine them both to one array - newpossibleMatches
}

export const getRegisteredDetails = (personID) => {
    return (dispatch, getState) => {
        let { DB } = getState();
        http.get('/getPersonDetails', { params: { ID: personID } }).then(res => {
            const { currentDBPerson, courses } = DB;
            let indexToSave = (currentDBPerson.index || currentDBPerson.index === 0) ? currentDBPerson.index : currentDBPerson.previousIndex;
            let registeredPerson = {
                node: {
                    ...res.data,
                    courseID: currentDBPerson.node.courseID,
                    registeredCourse: [courses.find(course => course.id.toString() === currentDBPerson.node.courseID)]
                },
                newPerson: false,
                registeredPerson: true,  //means its person that not belong to matches but exist in db as registered to the selected course
                previousIndex: indexToSave
            }
            dispatch({ type: UPDATE_CURRENT_DB_FORM, payload: registeredPerson });
        }, err => {

        })
    }
}

//model for empty person (opening/evaluation) - maybe should be in a seperate file? class?
export const emptyPersonJsonFormats =
{
    opening: {
        ID: 'תלמיד חדש',
        firstName: '',
        lastName: '',
        firstNameEnglish: '',
        lastNameEnglish: '',
        phoneNumber: { id: '', value: '' },
        workPhoneNumber: { id: '', value: '' },
        extraPhone1: { id: '', value: '' },
        extraPhone2: { id: '', value: '' },
        email: '',
        street: '',
        city: "",
        company: '',
        mailApproval: false,
        gender: '',
        registeredCourse: []
    },
    evaluation: {
        ID: 'תלמיד חדש',
        firstName: '',
        lastName: '',
        firstNameEnglish: '',
        lastNameEnglish: '',
        phoneNumber: { id: '', value: '' },
        workPhoneNumber: { id: '', value: '' },
        extraPhone1: { id: '', value: '' },
        extraPhone2: { id: '', value: '' },
        email: '',
        company: '',
        mailApproval: false,
        gender: '',
        lecturerGrade: 0,
        materialGrade: 0,
        courseGrade: 0,
        registeredCourse: []
    }
}

