var moment = require('moment');

export const getSQLFormat = (date, currentFormat = null) => {
    let defaultCurrentFormat = currentFormat ? currentFormat : "DD/MM/YYYY";
    return moment(date, defaultCurrentFormat).format("YYYY-MM-DD");
}

export const getLocalFormat = (date) => {
    return moment(date).format("DD/MM/YYYY");
}

export const isValid = (date, format) => {
    let momentDate = moment(date, format);
    if (momentDate.isValid())
        return momentDate.toDate();
    return false;
}

export const subtractTimeFromDate = (date, extraTime, timeType) => {  //timeType means hours('h')/days('d')..
    return moment(date).subtract(extraTime, timeType).toDate();
}