import { UPDATE_POPUP_CONFIG } from '../actions/types';

const initialState = {
    title: '',
    messages: [],
    confirm: null,
    onConfirm: null,
    onCancel: null,
    btns: null
}

export default (popUpConfig = initialState, action) => {
    switch (action.type) {
        case UPDATE_POPUP_CONFIG:
            return {...action.payload};
        default:
            return popUpConfig;
    }
}