import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signIn, signOut } from '../../actions/authActions';
import { NavLink, withRouter } from 'react-router-dom';
import '../../css/Navbar.css';

class Auth extends Component {
    render() {
        if (this.props.isSignedIn)
            return (<a><button onClick={this.props.signOut} className="logOut-btn">Log out</button></a>)
        else return (<NavLink exact to="/admin/login">Login</NavLink>)
    }
}

const mapStateToProps = (state) => {
    return { isSignedIn: state.currentUser.isSignedIn }
}

export default withRouter(connect(mapStateToProps, { signIn, signOut })(Auth))
