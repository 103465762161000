import React from 'react';

const NotFound = () => {
    return (
        <div className="container center">
            <br></br><br></br>
            <h3>Oops... Looks like this page doesn't exist.</h3>
            <h6>Please make sure that you entered the URL correctly or contact the administrator.</h6>
            <br></br>
            <img src={require('../assets/images/robotBanana404.jpg')} />
        </div>
    )
}

export default NotFound;