import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import Auth from '../login/Auth';
import '../../css/Navbar.css';
import { connect } from 'react-redux';


class Navbar extends Component {
    render() {
        return (
            <nav className="navbar-fixed #f57f17 yellow darken-4">
                <div className="nav-wrapper">
                    {this.props.DBNameMsg ? <span className="dbNameErrorMsg">{this.props.DBNameMsg}</span> : <></>}
                    <ul className="right hide-on-med-and-down">
                        <li><NavLink exact to="/admin/dateForm/opening">Opening Form</NavLink></li>
                        <li><NavLink exact to="/admin/dateForm/evaluation">Evaluation Form</NavLink></li>
                        <li><NavLink exact to="/admin/dateForm_evalReport">Evaluation Report</NavLink></li>
                        <li><NavLink exact to="/admin/form">Form</NavLink></li>
                        <li><Auth /></li>
                    </ul>
                </div>
            </nav>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        DBNameMsg: state.DBNameInfoMsg
    }
}

export default withRouter(connect(mapStateToProps)(Navbar));