import http from '../api/server';
import { SIGN_IN, SIGN_OUT, USER_NOT_FOUND_ERROR, UPDATE_LOADING, GET_DB_NAME } from './types';
import history from '../helpers/history';
import { reportError } from './errorActions';


export const checkAuth = () => {
    //axios call and then call dispatch with signin/signout action
    return (dispatch, getState) => {
        http.get("/precheckAuthorization").then(({ data }) => {
            if (data.isLoggedIn) {
                dispatch({ type: SIGN_IN });
                dispatch({ type: GET_DB_NAME, payload: data.DBName });
            }
        }, err => {
            if (err.response && err.response.status === 401) {
                dispatch({ type: SIGN_OUT });
                dispatch({ type: GET_DB_NAME, payload: err.response.data.DBName });
            }
            else {
                reportError(err, dispatch);
            }
        }).finally(() => {
            dispatch({ type: UPDATE_LOADING });
        });
    }
}

export const signIn = (userName, password) => {
    //axios call and then call dispatch with SIGN_IN/USER_NOT_FOUND
    return (dispatch) => {
        http.post("/postLogin", { email: userName, password }).then(res => {
            if (res.data.success) {
                dispatch({ type: SIGN_IN });
                history.push("/admin/dateForm/opening");
            }
            else
                dispatch({ type: USER_NOT_FOUND_ERROR, payload: true });
        }, err => {
            reportError(err, dispatch);
        })
    }
}

export const clearUserNotFoundMsg = () => {
    return {
        type: USER_NOT_FOUND_ERROR,
        payload: false
    }
}

export const signOut = () => {
    //axios call and then call dispatch with SIGN_OUT
    return (dispatch) => {
        http.post("/postLogout").then(res => {
            if (res.data) {
                dispatch({ type: SIGN_OUT })
            }
        }, err => {
            if (err.response && err.response.status === 401)
                dispatch({ type: SIGN_OUT });
            else
                reportError(err, dispatch);
        })
    }
}



