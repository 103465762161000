import { REPORT_ERROR,CLEAN_ERRORS } from '../actions/types';

const initError = {
    message: ''
}
export default (error = initError, action) => {
    switch (action.type) {
        case REPORT_ERROR:
            return { message: action.payload };
        case CLEAN_ERRORS:
            return initError;
        default:
            return error;
    }
}