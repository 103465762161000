import React from 'react';
import Navbar from './layout/Navbar';

// This is a wrappwe for every component which should include the navbar above her.
// (used in all admin routes, so pageNotFound component is the only route which does not use it).
const Wrapper = (props) => {
    return (
        <>
            <Navbar />
            <div className="app container">
                {props.children}
            </div>
        </>
    )
}
export default Wrapper;