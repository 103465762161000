import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Wrapper from './Wrapper';
import { connect } from 'react-redux';
import { checkAuth } from '../actions/authActions';

//this is a wrapper for all admin component.In its first render, it calls checkAuth.
//If the target route was 'admin/login' originally, it only render login component without to check if admin is logged in.
//In the other admin routes, it check - if admin logged in, it render the target component. if not, it redirect to login.
//In both cases we need to precheck auth, because all admin routes are wrapped with navbar, and we need to update navbar Auth button (login/logout).
class AdminRoute extends Component {

    componentDidMount() {
        this.props.checkAuth();
    }

    render() {
        const { component: Component, isSignedIn, loading, ...rest } = this.props;
        return (
            loading ? "" :
                (
                    <Route exact {...rest} render={(props) => {

                        if (props.location.pathname === '/admin/login')
                            return <Wrapper> <Component {...props} />  </Wrapper>;
                        else return isSignedIn ?

                            <Wrapper> <Component {...props} />  </Wrapper> :

                            <Redirect to={{
                                pathname: '/admin/login',
                                state: { from: props.location }
                            }} />
                    }} />
                )
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isSignedIn: state.currentUser.isSignedIn,
        loading: state.loading
    }
}

export default connect(mapStateToProps, { checkAuth })(AdminRoute);
