import React, { Component } from 'react';
import { Route, Switch, Redirect, Router } from 'react-router-dom';
import history from '../helpers/history';
import { connect } from 'react-redux';
import Login from './login/Login';
import DateForm from './forms/excelForms/DateForm';
import DateFormReport from './forms/report/DateForm';
import EvaluationReport from './forms/report/EvaluationReport';
import FormLayout from './forms/excelForms/updateDatabase/FormLayout';
import NotFound from './NotFound';
import PopUp from '../helpers/PopUp';
import AdminRoute from './AdminRoute';

import '../css/App.css';

class App extends Component {

  render() {
    return (
      <Router history={history}>
        <div>
          <div>
            <Switch>
              <AdminRoute exact path="/admin/login" component={Login} />
              <Redirect exact path="/admin" to="/admin/dateForm/opening" />
              <AdminRoute exact path="/admin/dateForm/opening" component={DateForm} />
              <AdminRoute exact path="/admin/dateForm/evaluation" component={DateForm} />
              <AdminRoute exact path="/admin/form" component={FormLayout} />
              <AdminRoute exact path="/admin/dateForm_evalReport" component={DateFormReport} />
              <AdminRoute exact path="/admin/evalReport" component={EvaluationReport} />
              <Route path="/*" render={() => <NotFound />} />
            </Switch>
            {
              this.props.error ? <PopUp title="שגיאה" messages={[this.props.error]} open={true} /> :
                <PopUp title={this.props.popUpConfig.title}
                  messages={this.props.popUpConfig.messages}
                  confirm={this.props.popUpConfig.confirm} btns={this.props.popUpConfig.btns}
                  onConfirm={this.props.popUpConfig.onConfirm} onCancel={this.props.popUpConfig.onCancel} />
            }
          </div>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.error.message,
    popUpConfig: state.popUpConfig
  }
}

export default connect(mapStateToProps)(App);




