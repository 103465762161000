import { SIGN_IN, SIGN_OUT, USER_NOT_FOUND_ERROR, UPDATE_LOADING, GET_DB_NAME } from '../actions/types';

export const currentUser = (currentUser = { isSignedIn: null, userNotExistError: null }, action) => {
    switch (action.type) {
        case SIGN_IN:
            return { userNotExistError: null, isSignedIn: true };
        case SIGN_OUT:
            return { userNotExistError: null, isSignedIn: false };
        case USER_NOT_FOUND_ERROR:
            return { ...currentUser, userNotExistError: action.payload };
        default:
            return currentUser;
    }
}

export const loading = (loading = true, action) => { //only after checkAuth http is finished (for updating app that loading is finished).
    if (action.type === UPDATE_LOADING)
        return false;
    else return loading;
}

export const DBNameInfoMsg = (DBMsg = "", action) => {
    if (action.type === GET_DB_NAME) {
        let dbName = action.payload;
        if (process.env.NODE_ENV === 'production') {
            if (dbName === "ydc_dev")
                return "Pay attention: You are NOT using the real DB. Please contact the administrator";
            else return "";
        }
        else {
            if (dbName === "ydc_copy")
                return "Pay attention: You are using the real DB";
            else return "";
        }
    }
    else return DBMsg;
}

